@font-face
    font-family: 'main'
    src: url(../fonts/Montserrat.ttf)

@font-face
    font-family: 'display'
    src: url(../fonts/Gropled-Bold.otf)

$main-color: #E0E7F8
// $secondary-color: #5969a1
$secondary-color: #272E48
$third-color: #FFC93C

::-webkit-scrollbar
    width: 10px

::-webkit-scrollbar-track
    background-color: $main-color

::-webkit-scrollbar-thumb
    background-color: $secondary-color
    border-radius: 10px

@keyframes bounce
    0%, 100%
        transform: translateY(0)
        transform: translateY(0)
    10%
        transform: translateY(-40px)
        transform: translateY(-40px)
    20%
        transform: translateY(0px)
        transform: translateY(0px)
    40%
        transform: translateY(-30px)
        transform: translateY(-30px)
    50%
        transform: translateY(0px)
        transform: translateY(0px)
    70%
        transform: translateY(-10px)
        transform: translateY(-10px)
    80%
        transform: translateY(0px)
        transform: translateY(0px)

@mixin content
    position: relative
    // margin: 30px 50px
    // width: 90vw
    display: flex
    justify-content: center
    align-items: center

@mixin transition
    transition: all 0.5s ease-out

@mixin button
    cursor: pointer
    box-shadow: none
    min-height: 46px
    width: fit-content
    padding: 10px 30px
    color: $secondary-color
    background-color: $main-color
    border-radius: 30px
    border: none
    font-family: 'main'
    font-size: 16px
    text-transform: uppercase
    @include transition
    &:hover
        box-shadow: inset 9px 9px 10px 0px rgba(0, 0, 0, 0.61)
        @include transition

@mixin buttonContainer
    z-index: 2
    cursor: pointer
    width: fit-content
    height: fit-content
    border-radius: 30px
    box-shadow: 4px 4px 7px 0px rgba(0, 0, 0, 0.61)
    // margin-right: 50px
    margin: 10px
    @include transition
    &:hover
        box-shadow: none
        @include transition

@mixin block
    position: relative
    display: flex
    justify-content: center
    align-items: center
    min-height: calc( 100vh - 80px )
    max-height: calc( 100vh - 80px )

@mixin header1
    font-family: 'display'
    line-height: 125%
    font-size: 60px
    font-weight: 700
    @media ( min-width: 0px ) and ( max-width: 650px )
        font-size: 48px

@mixin header2
    font-family: 'main'
    font-size: 36px
    font-weight: 600

@mixin header3
    font-family: 'main'
    font-size: 24px
    font-weight: 500

@mixin text
    font-family: 'main'
    font-size: 18px
    font-weight: 500

@mixin link
    font-family: 'main'
    font-size: 16px
    font-weight: 400
