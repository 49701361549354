@import 'variables'



.main
    &__docs
        &__modal
            &__layout
                border-radius: 30px
                width: 90vw !important
                height: 90vh !important
                display: flex
                justify-content: center
                align-items: center
                &__content
                    position: relative
                    width: fit-content
                    // max-width: 70vw
                    display: flex
                    flex-direction: column
                    padding: 0px 20px 0px 50px
                    margin: 0px 22px 0px 0px
                    max-height: 80vh !important
                    overflow-y: auto
                    overflow-x: clip
                    @media (max-width: 768px)
                        padding: 0px 10px 0px 35px
                    &__title
                        @include header3
                        text-align: center
                        margin-bottom: 20px
                        color: $secondary-color
                        @media (max-width: 670px)
                            font-size: 20px
                        @media (max-width: 550px)
                            font-size: 18px
                    &__a
                        @include link
                        color: $secondary-color
                        text-decoration: none
                        text-align: center
                        margin-bottom: 10px
                        @include transition
                        &:hover
                            text-decoration: underline
                            color: #c18f0d
                            @include transition
                    &__p
                        @include link
                        color: $secondary-color
                        text-decoration: none
                        text-align: start
                        padding: 0px 50px
                        margin-bottom: 10px
                        line-height: 200%
                        @include transition
                        @media (max-width: 768px)
                            font-size: 16px
                    &__ol
                        &__li
                            @include text
                            color: $secondary-color
                            text-decoration: none
                            text-align: start
                            margin-bottom: 10px
                            line-height: 200%
                            @include transition
                            @media (max-width: 768px)
                                font-size: 16px
                            @media (max-width: 425px)
                                font-size: 14px
                            &__ul
                                &__li
                                    @include link
                                    color: $secondary-color
                                    text-decoration: none
                                    text-align: start
                                    margin-bottom: 10px
                                    line-height: 200%
                                    @include transition
                                    @media (max-width: 768px)
                                        font-size: 14px
                                    @media (max-width: 425px)
                                        font-size: 12px
                                &__ways
                                    display: flex
                                    flex-wrap: wrap
                                    justify-content: center
                                    align-items: center
                                    &__img
                                        margin-right: 20px
                                        width: 150px
                                        @media (max-width: 768px)
                                            width: 70px
                                        @media (max-width: 425px)
                                            width: 40px
                    &__first
                        @include text
                        color: $secondary-color
                        text-decoration: none
                        text-align: start
                        margin-bottom: 10px
                        line-height: 200%
                        @include transition
                        @media (max-width: 768px)
                            font-size: 16px
                        @media (max-width: 425px)
                            font-size: 14px
                    &__second, &__third
                        @include link
                        color: $secondary-color
                        text-decoration: none
                        text-align: start
                        margin-bottom: 10px
                        line-height: 200%
                        @include transition
                    &__second
                        margin-left: 10px
                    &__third
                        margin-left: 20px
                    @media (max-width: 768px)
                        &__second, &__third
                            font-size: 14px
                    @media (max-width: 425px)
                        &__second, &__third
                            font-size: 12px

