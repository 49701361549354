@import 'variables'

.footer
    height: 250px
    display: flex
    align-items: center
    justify-content: space-between
    min-width: 100%
    position: sticky
    top: 0px
    z-index: 20
    background-color: $secondary-color
    &__content
        height: 100%
        width: 100%
        display: flex
        justify-content: space-between
        align-items: center
        margin: 10px 40px 30px 40px
        &__left
            height: 100%
            display: flex
            flex-direction: column
            justify-content: space-evenly
            position: relative
            &__links
                display: flex
                flex-wrap: wrap
                justify-content: flex-start
                &__link
                    @include link
                    color: $main-color
                    margin-right: 15px
                    @include transition
                    &:hover
                        text-decoration: underline
                        color: $third-color
                        @include transition
            &__payments
                // width: 30vw
                display: flex
                // flex-wrap: wrap
                justify-content: space-evenly
                &__payment
                    width: 50px
                    margin-right: 10px
                    margin-bottom: 10px
                    &:nth-child(5)
                        margin-right: 0px
            &__rights
                font-family: 'main'
                font-size: 14px
                font-weight: 300
                color: $main-color
        &__right
            display: flex
            margin-right: 65px
            &__contacts
                display: flex
                flex-direction: column
                margin-right: 20px
                &__link
                    margin-bottom: 5px
                    @include link
                    color: $main-color
                    margin-right: 15px
                    font-weight: 500
                    @include transition
                    &:hover
                        text-decoration: underline
                        color: $third-color
                        @include transition
                    &:nth-child(2)
                        margin-bottom: 15px
                &__info
                    @include link
                    font-size: 14px
                    color: $main-color
            &__networks
                display: flex
                flex-direction: column
                &__item
                    width: fit-content
                    &__img
                        width: 50px