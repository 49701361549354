@import 'variables'

.main
    min-width: 100%
    max-width: 100%
    &__section
        &__advantages
            position: relative
            @include content
            flex-direction: column
            margin: 0px
            padding-top: 50px
            &__h1
                @include header1
                color: $secondary-color
                margin-bottom: 50px
            &__container
                display: flex
                justify-content: space-evenly
                flex-wrap: wrap
                min-width: 90vw
                max-width: 90vw
                &__slider
                    width: 100%
                    .swiper-button-prev
                        content: url(../img/arrow_left.svg)
                    .swiper-button-next
                        content: url(../img/arrow_right.svg)
                    &__slide
                        display: flex !important
                        flex-direction: column
                        align-items: center
                        justify-content: flex-start
                        margin: 0px 0px 40px 0px
                        &__img
                            margin-bottom: 20px
                        &__name
                            @include header3
                            text-align: center
                            min-width: 350px
                            max-width: 350px

// .swiper-button-prev
//     content: url(../img/arrow_left.svg)

// .swiper-button-next
//     content: url(../img/arrow_right.svg)
