@import 'variables'

.main
    &__section
        &__teachers
            position: relative
            @include content
            flex-direction: column
            margin: 0px
            padding-top: 50px
            margin-bottom: 50px
            &__title
                @include header1
                text-align: center
                margin: 10px 30px
                color: $secondary-color
            &__container
                min-height: fit-content
                max-height: fit-content
                display: flex
                justify-content: space-evenly
                align-items: flex-start
                flex-wrap: wrap
                min-width: calc( 100vw - 200px )
                max-width: calc( 100vw - 200px )
                &__slider
                    width: 90vw
                    display: flex !important
                    justify-content: center
                    align-items: center
                    &.swiper-wrapper
                        align-items: center
                    &__slide
                        display: flex !important
                        flex-direction: column
                        justify-content: center
                        align-items: center
                        margin-bottom: 40px
                        &__name
                            @include header3
                            color: $secondary-color
                            font-size: 18px
                            text-align: center
                            width: 80%
                        &__card
                            position: relative
                            width: 100%
                            width: 70%
                            margin-bottom: 10px
                            &__img
                                width: 100%
                                border-radius: 30px
                                box-shadow: 7px 7px 4px 0px rgba(0, 0, 0, 0.25)
                            &__hover
                                opacity: 0
                                &:hover
                                    opacity: 1
                                    @include transition
                                background: linear-gradient(360deg, rgba(0, 0, 0, 0.70) 6.77%, rgba(0, 0, 0, 0.00) 100%)
                                border-radius: 30px
                                position: absolute
                                bottom: 5px
                                height: 100%
                                width: 100%
                                display: flex
                                flex-direction: column
                                justify-content: flex-end
                                align-items: flex-start
                                padding: 0px 
                                @include transition
                                &__container
                                    padding: 15px
                                    max-height: calc( 100% - 30px )
                                    display: flex
                                    flex-direction: column
                                    justify-content: flex-end
                                    align-items: flex-start
                                    &__name
                                        color: #fff
                                        margin-bottom: 10px
                                        font-family: 'main'
                                        font-size: 16px
                                        font-weight: 500
                                    &__info
                                        color: #fff
                                        margin-bottom: 5px
                                        font-family: 'main'
                                        font-size: 14px
                                        font-weight: 400
                                        overflow: hidden
                                        text-overflow: ellipsis
                                        max-height: 60%
                                    &__button
                                        @include button
                                        background-color: rgba(0, 0, 0, 0.30)
                                        border-radius: 15px
                                        color: #fff
                                        display: flex
                                        align-items: center
                                        justify-content: center
                                        padding: 10px 20px
                                        font-family: 'main'
                                        font-size: 14px
                                        font-weight: 400
                                        text-transform: none
                                        align-self: flex-end
                                        &__arrow
                                            margin-left: 5px
                                            // align-self: flex-end
                                            // position: absolute
                                            // margin: auto
            &__popUp
                margin: auto
                display: flex !important
                justify-content: center
                align-items: center
                position: relative
                &__modal
                    position: relative
                    width: 60vw
                    height: 80vh
                    margin: 0px
                    &__content
                        max-height: 80vh !important
                        width: 100% !important
                        margin: 0px !important
                        border-radius: 30px
                        z-index: 1
                        display: flex
                        justify-content: space-evenly
                        padding: 45px 45px 30px 30px
                        position: relative
                        background: linear-gradient(90deg, rgba(255, 201, 60, 0.33) 0%, rgba(255, 201, 60, 0.00) 100%) !important
                        &__left
                            width: 30%
                            margin-right: 60px
                            display: flex
                            flex-direction: column
                            justify-content: flex-start
                            align-items: center
                            &__img
                                width: 60%
                                border-radius: 200px
                                margin-bottom: 20px
                            &__info
                                display: flex
                                flex-direction: column
                                align-items: center
                                &__name
                                    @include header3
                                    color: $secondary-color
                                    font-weight: 600
                                    margin-bottom: 5px
                                    text-align: center
                                &__post
                                    @include text
                                    font-size: 14px
                                    font-weight: 400
                                    color: #636363
                                    text-align: center
                        &__right
                            width: 60%
                            max-height: 40rem
                            overflow-y: auto
                            padding-right: 20px
                            &__text
                                @include text
                                margin-bottom: 15px
                                color: $secondary-color
                                font-weight: 400
                                font-size: 16px
                                line-height: 175%
                                text-indent: 20px


.window__teacher
    background-color: $main-color !important