@use 'variables'

.main
    &__section
        display: flex !important
        justify-content: center !important
        align-items: center !important
        &__form
            margin: 0px auto
            display: flex !important
            justify-content: center !important
            align-items: center
            position: relative
            &__layout
                display: flex !important
                flex-direction: column
                justify-content: center
                align-items: center
                width: fit-content
                height: fit-content
                position: relative
                max-height: 70vh
                border-radius: 30px
                padding: 25px 20px 20px
                background-color: $main-color !important
                height: fit-content
                max-height: fit-content
                overflow: auto
                &__success
                    position: absolute
                    width: 100%
                    height: 100%
                    display: flex
                    flex-direction: column
                    justify-content: center
                    align-items: center
                    border-radius: 30px
                    background-color: $main-color
                    &__title
                        @include header3
                        margin-bottom: 10px
                        text-align: center
                    &__p
                        @include link
                        text-align: center
                        margin-bottom: 15px
                &__title
                    @include header3
                    margin-bottom: 10px
                    text-align: center
                &__text
                    @include link
                    text-align: center
                    margin-bottom: 15px
                    width: 80%
                &__item
                    width: fit-content
                    height: fit-content
                    margin-bottom: 15px
                    display: flex
                    flex-direction: column
                    justify-content: center
                    align-items: center
                    &__label
                        @include text
                        font-size: 14px
                        margin-right: 15px
                    &__input
                        height: fit-content
                        padding: 10px
                        border-radius: 30px
                        background-color: $main-color
                        border: 1px solid #474747
                        &:focus
                            border: none
                            border: 2px solid $third-color
                            outline: none
                    &__error
                        @include text
                        font-size: 12px
                        color: #550000
                        width: 100%
                        margin: 0px
                        padding: 0px
                        text-align: center
                &__button
                    @include button
                    background-color: $secondary-color
                    color: $main-color

.main__section__form__layout__button.disabled
    background-color: #747474
    cursor: not-allowed
    &:hover
        box-shadow: none
        cursor: not-allowed

.payment-iphone-class
    justify-content: flex-start
    width: 100%
    position: relative
    padding: 100px 0px 0px 0px
    min-height: calc( 100vh - 90px )
    height: calc( 100vh - 90px )
    max-height: calc( 100vh - 90px )
    border-radius: 0px
    overflow: hidden
    &__backButton
        position: absolute
        top: 50px
        display: flex
        justify-content: center
        align-items: center
        padding: 5px 10px
        border-radius: 15px
        background-color: transparent
        border: none
        color: $secondary-color
        z-index: 1
        &:hover
            text-decoration: underline
        &__arrow
            height: 20px
            margin-right: 5px
    &__waves
        position: absolute
        bottom: -10px
        z-index: 0

// .alfa-form-wrapper
//     min-height: fit-content !important

// #lead-form
//     min-height: fit-content !important
