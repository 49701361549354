@import 'variables'


//header
// @media ( min-width: 0px ) and ( max-width: 768px )
//     .header
//         &__content
//             &__button
//                 display: none

// @media ( min-width: 0px ) and ( max-width: 1024px )
//     .header
//         &__content
//             &__button
//                 margin-right: 100px

@media ( min-width: 0px ) and ( max-width: 1342px )
    .header
        &__content
            &__nav
                &__a
                    margin-right: 20px
                    @media ( min-width: 0px ) and ( max-width: 1082px )
                        margin-right: 15px

//footer
@media ( min-width: 769px ) and ( max-width: 1024px )
    .footer
        &__content
            &__left
                &__links
                    flex-direction: column
                    &__link
                &__payments
                    &__payment
                &__rights
            &__right
                @media ( min-width: 0px ) and ( max-width: 1000px )
                    flex-direction: column
                    &__contacts
                        &__link
                        &__info
                            margin-bottom: 10px
                    &__networks
                        flex-direction: row
                        &__item
                            margin-right: 20px
                            &__img
                &__contacts
                    &__link
                    &__info
                &__networks
                    &__item
                        &__img

@media ( min-width: 0px ) and ( max-width: 768px )
    .footer
        height: fit-content
        display: flex
        justify-content: center
        align-items: center
        &__content
            margin: 7px
            display: flex
            flex-direction: column
            justify-content: center
            align-items: flex-start
            width: fit-content
            &__contacts
                width: 100%
                display: flex
                align-items: center
                justify-content: flex-start
                // flex-direction: column
                &__links
                    display: flex
                    flex-direction: column
                    &__link
                        margin-bottom: 5px
                        @include link
                        font-size: 14px
                        color: $main-color
                        margin-right: 15px
                        font-weight: 500
                        @include transition
                        &:hover
                            text-decoration: underline
                            color: $third-color
                            @include transition
                        &:nth-child(2)
                            margin-bottom: 15px
                &__networks
                    width: fit-content
                    display: flex
                    justify-content: space-evenly
                    align-self: center
                    flex-direction: column
                    &__item
                        &__img
                            width: 30px
            &__docs
                display: flex
                flex-direction: column
                // align-items: center
                margin-bottom: 10px
                &__link
                    @include link
                    font-size: 14px
                    color: $main-color
                    margin-right: 15px
                    @include transition
                    &:hover
                        text-decoration: underline
                        color: $third-color
                        @include transition
            &__payments
                display: flex
                justify-content: center
                align-items: center
                align-self: center
                &__payment
                    width: 30px
                    margin-right: 10px
                    margin-bottom: 10px
                    &:nth-child(5)
                        margin-right: 0px
            &__info
                @include link
                font-size: 12px
                color: $main-color
                // text-align: center
                margin-bottom: 10px
            &__rights
                font-family: 'main'
                font-size: 10px
                font-weight: 300
                color: $main-color

// advantages
@media ( min-width: 0px ) and ( max-width: 650px )
    .main
        &__section
            &__advantages
                margin-bottom: 50px 
                &__h1
                    @include header1
                    font-size: 48px
                    color: $secondary-color
                    margin-bottom: 50px
                &__container
                    min-width: calc( 100vw - 30px )
                    max-width: calc( 100vw - 30px )
                    &__slider
                        @media ( min-width: 0px ) and ( max-width: 426px )
                            .swiper-button-prev
                                display: block !important
                                content: url('../img/arrow-left-rates.svg')
                                top: auto !important
                                bottom: 0px !important
                                left: 10%
                                z-index: 11
                            .swiper-button-next
                                display: block !important
                                content: url('../img/arrow-right-rates.svg')
                                top: auto !important
                                bottom: 0px !important
                                right: 10%
                                z-index: 11
                        &__slide
                            &__img
                            &__name
                                @include header3
                                font-size: 18px
                                text-align: center
                                min-width: 250px
                                max-width: 250px

//forWhom
@media ( min-width: 0px ) and ( max-width: 950px )
    .main
        &__section
            &__forWhom
                &__container
                    &__h1
                        margin-bottom: 0px
                    &__content
                        flex-direction: column-reverse
                        align-items: center
                        justify-content: flex-start
                        &__box
                            min-width: 95vw
                            max-width: 95vw
                            margin-bottom: 30px
                            &__title
                                min-width: 70vw
                                max-width: 70vw
                                margin-bottom: 40px
                                font-size: 18px
                            &__choice
                                flex-direction: row
                                align-items: flex-end
                                justify-content: space-evenly
                                &__age
                                    justify-content: space-evenly
                                    &:nth-child(1)
                                        margin-right: 0px
                                        margin-bottom: 0px
                                    &__img
                                        width: 30vw
                                        margin-bottom: 0px
                                    &__button
                                        @include button
                                        font-size: 13px
                                        padding: 5px 10px
                                        min-height: 35px
                                        margin-top: 10px
                                        text-transform: none
                                        color: $main-color
                                        background-color: $secondary-color
                        &__info
                            min-width: 80vw
                            max-width: 80vw
                            max-height: 50vh
                            margin-right: 0px


//about
@media ( min-width: 0px ) and ( max-width: 900px )
    .main
        &__section
            &__about
                &__container
                    @media ( min-width: 601px ) and ( max-width: 930px )
                        padding-bottom: 150px
                    @media ( min-width: 0px ) and ( max-width: 600px )
                        padding-bottom: 100px
                    &__button
                    &__group1
                        width: 90vw
                        align-items: center
                        @media ( min-width: 0px ) and ( max-width: 400px )
                            margin-bottom: 0px
                        &__left
                            display: flex
                            flex-direction: column
                            align-items: center
                            justify-content: space-between
                            &__title
                                margin-bottom: 20px
                                @media ( min-width: 0px ) and ( max-width: 400px )
                                    padding: 25px 70px
                                    &__h1
                                        font-size: 36px
                            &__points
                                margin-left: 0px
                                &__point
                                    @media ( min-width: 0px ) and ( max-width: 550px )
                                        display: flex
                                        align-items: flex-start
                                        // &__text
                                            // font-size: 18px
                                        &__img
                                            // width: 15vw
                                            width: 2.5rem
                                            margin-right: 20px
                        &__right
                            display: none
                    &__group2
                        width: 90vw
                        align-self: center
                        &__right
                            width: 90vw
                            display: flex
                            flex-direction: column
                            align-items: center
                            justify-content: space-between
                            &__title
                                padding: 40px 70px 15px
                                margin-bottom: 20px
                                @media ( min-width: 0px ) and ( max-width: 400px )
                                    padding: 40px 45px 15px
                                    &__h1
                                        font-size: 18px
                                &__h1
                                    font-size: 36px
                            &__points
                                margin-left: 0px
                                align-self: flex-start
                                &__point
                                    @media ( min-width: 0px ) and ( max-width: 550px )
                                        margin-bottom: 60px
                                        display: flex
                                        align-items: flex-start
                                        // &__text
                                            // font-size: 18px
                                        &__img
                                            width: 2.5rem
                                            margin-right: 20px
                        &__left
                            display: none
                    &__button
                        margin: 0px

//results
@media ( min-width: 0px ) and ( max-width: 768px )
    .main
        &__section
            &__results
                &__container
                    &__title
                        font-size: 18px
                    &__button
                        margin-right: 0px
                        &__hover
                    &__content
                        &__points
                            margin-left: 0px
                            width: 90vw
                            &__point
                                display: flex
                                align-items: flex-start
                                margin-bottom: 15px
                                &__img
                                    margin-right: 10px
                                &__text
                                    font-size: 16px
                                    font-weight: 400
                                    line-height: 175%
                        &__img
                            display: none


//rates
@media ( min-width: 0px ) and ( max-width: 1100px )
    .main
        &__section
            &__rates
                &__container
                    &__title
                    &__choice
                        @media ( min-width: 0px ) and ( max-width: 770px )
                            width: 90vw !important
                            justify-content: center !important
                            align-items: center !important
                            &__age
                                font-size: 13px !important
                                margin-bottom: 10px !important
                                &__span
                                    font-size: 10px !important
                        &__age
                            &:nth-child(1)
                                margin-right: 0px !important
                            &:nth-child(2)
                                margin-right: 0px !important
                    &__packages
                        &__age
                            flex-direction: column
                            justify-content: center
                            align-items: center
                            &__package
                                &__duration
                                    &__time
                                &__title
                                .groupTitle
                                    margin-top: 25px
                                    margin-bottom: 15px
                                &__slider
                                    margin-bottom: 0px 
                                    width: 98vw !important
                                    &__slide
                                        &__card
                                            min-height: 570px !important
                                            max-height: 570px !important
                                            // width: 35vw !important
                                            // padding: 10px 3px !important
                                            @media ( min-width: 0px ) and ( max-width: 376px )
                                                width: 90vw !important
                                                padding: 10px 3px !important
                                            &__h3
                                            &__title
                                                @media ( min-width: 0px ) and ( max-width: 376px)
                                                    font-size: 24px !important
                                            &__img
                                            &__features
                                                @media ( min-width: 0px ) and ( max-width: 376px)
                                                    width: 90% !important
                                                &__point
                                            &__price
                                                font-size: 24px !important
                                                &__note
                                                    font-size: 10px !important
                                            &__button

//feedback
@media ( min-width: 0px ) and ( max-width: 1325px )
    .main
        &__section
            &__feedback
                &__waves
                &__container
                    &__title
                    &__content
                        &__slider
                            @media ( min-width: 0px ) and ( max-width: 768px )
                                min-width: 98vw 
                                max-width: 98vw
                            @media ( min-width: 0px ) and ( max-width: 650px )
                                .swiper-pagination 
                                .swiper-button-prev
                                    display: block !important
                                    // content: url('../img/arrow_left_light.svg')
                                    top: auto !important
                                    bottom: 0px !important
                                    left: 10%
                                    z-index: 11
                                .swiper-button-next
                                    display: block !important
                                    // content: url('../img/arrow_right_light.svg')
                                    top: auto !important
                                    bottom: 0px !important
                                    right: 10%
                                    z-index: 11
                            &__slide
                                margin-bottom: 20px
                                &__comment
                                    width: 45vw
                                    // height: fit-content
                                    @media ( min-width: 0px ) and ( max-width: 1024px )
                                        width: 60vw
                                    @media ( min-width: 0px ) and ( max-width: 768px )
                                        width: 70vw
                                    &__points1
                                    &__points2
                                    &__avatar
                                        &__img
                                        &__state
                                    &__likes
                                        &__img
                                    &__info
                                        &__name
                                            &:hover
                                        &__date
                                            &:hover
                                        &__text

@media ( min-width: 0px ) and ( max-width: 500px )
    .main
        &__section
            &__feedback
                &__waves
                &__container
                    max-height: fit-content
                    min-height: fit-content
                    &__title
                        margin-top: 30px
                    &__content
                        &__slider
                            .swiper-pagination 
                                width: 60vw !important
                                left: 19% !important
                            &__slide
                                margin-bottom: 60px
                                &__comment
                                    height: 30vh
                                    width: 80vw
                                    flex-direction: column
                                    // padding: 35px 10px 30px
                                    &__person
                                        width: 100%
                                        display: flex
                                        align-items: flex-start
                                        justify-content: flex-start
                                        &__avatar
                                            margin-right: 30px
                                            display: flex
                                            flex-direction: column
                                            justify-content: flex-start
                                            align-items: center
                                            &__img
                                                border-radius: 30px
                                                margin: 5px 15px
                                                width: 60px
                                            &__state
                                                font-family: 'main'
                                                font-size: 12px
                                                font-weight: 400
                                                color: #575757
                                        &__links
                                            display: flex
                                            flex-direction: column
                                            &__name
                                                color: $secondary-color
                                                font-weight: 600
                                                text-decoration: none
                                                // margin-bottom: 5px
                                                @include transition
                                                &:hover
                                                    text-decoration: underline
                                                    color: #c48e19
                                                    @include transition
                                            &__date
                                                margin-bottom: 10px
                                                font-size: 13px
                                                color: #616161b6
                                                font-weight: 400
                                                text-decoration: none
                                                &:hover
                                                    text-decoration: underline
                                                    color: #c48e19
                                                    @include transition
                                    &__info
                                        &__text
                                            max-height: 11rem
                                            overflow-y: auto
                                            font-size: 14px
                                    &__likes
                                        top: 0px
                                        right: 0px

//teachers
@media ( min-width: 0px ) and ( max-width: 850px )
    .main
        &__section
            &__teachers
                &__title
                    @media ( min-width: 0px ) and ( max-width: 500px )
                        font-size: 36px
                &__container
                    min-width: 90vw
                    max-width: 90vw
                    &__slider
                        @media ( min-width: 0px ) and ( max-width: 550px )
                            .swiper-button-prev
                                display: block !important
                                top: auto !important
                                bottom: 0px !important
                                left: 10%
                                z-index: 11
                            .swiper-button-next
                                display: block !important
                                top: auto !important
                                bottom: 0px !important
                                right: 10%
                                z-index: 11
                        &__slide
                            &__name
                            &__card
                                @media ( min-width: 0px ) and ( max-width: 550px )
                                    width: 90%
                &__popUp
                    &__modal
                        &__content
                            &__left
                                &__img
                                &__name
                                &__post
                            &__right
                                &__text
                                    font-size: 14px

@media ( min-width: 0px ) and ( max-width: 1240px )
    .main
        &__section
            &__teachers
                &__popUp
                    @media ( min-width: 0px ) and ( max-width: 900px )
                        // max-width: 90vw
                        // min-width: 90vw
                        &__modal
                            width: 90vw
                    &__modal
                        &__content
                            flex-direction: column
                            @media ( min-width: 0px ) and ( max-width: 570px )
                                padding: 25px 25px 15px 15px
                            &__left
                                margin-right: 0px
                                margin-bottom: 10px
                                width: 100%
                                flex-direction: row
                                align-items: flex-start
                                &__img
                                    width: 20%
                                    margin-right: 30px
                                    @media ( min-width: 0px ) and ( max-width: 950px )
                                        width: 40%
                                    @media ( min-width: 0px ) and ( max-width: 900px )
                                        width: 30%
                                &__info
                                    width: 50%
                                    @media ( min-width: 0px ) and ( max-width: 570px )
                                        &__name
                                            font-size: 4vw
                                        &__post
                                            font-size: 3vw
                                    &__name
                                        text-align: start
                                    &__post
                                        text-align: start
                            &__right
                                width: 100%
                                max-height: 20rem
                                @media ( min-width: 0px ) and ( max-width: 570px )
                                    max-height: 30rem
                                &__text

//diplomas
@media ( min-width: 0px ) and ( max-width: 850px )
    .main
        &__section
            &__diplomas
                &__title
                    @media ( min-width: 0px ) and ( max-width: 500px )
                        font-size: 36px
                &__container
                    min-width: 90vw
                    max-width: 90vw
                    &__slider
                        .swiper-pagination 
                            width: 70% !important
                            bottom: 0px !important
                            left: auto !important
                        @media ( min-width: 0px ) and ( max-width: 550px )
                            .swiper-button-prev
                                display: block !important
                                top: auto !important
                                bottom: 0px !important
                                left: 10%
                                z-index: 11
                            .swiper-button-next
                                display: block !important
                                top: auto !important
                                bottom: 0px !important
                                right: 10%
                                z-index: 11
                        &__slide
                            margin-bottom: 100px
                            &__img
                                max-height: 350px
                &__popUp
                    &__modal
                        &__content
                            &__img

//questions
@media ( min-width: 0px ) and ( max-width: 1240px )
    .main
        &__section
            &__questions
                 &__popUp
                    @media ( min-width: 0px ) and ( max-width: 900px )
                        // max-width: 90vw
                        // min-width: 90vw
                        &__modal
                            width: 90vw
                    &__modal
                        &__content
                            flex-direction: column
                            @media ( min-width: 0px ) and ( max-width: 570px )
                                padding: 25px 25px 15px 15px
                                &__title
                                    margin-bottom: 20px
                                &__answer
                                    max-height: 30rem
                            @media ( min-width: 0px ) and ( max-width: 500px )
                                padding: 30px 25px 15px 15px
                                &__title
                                    font-size: 18px
                                    margin-bottom: 20px
                                &__answer
                                    max-height: 30rem
                                    font-size: 16px





// @media ( min-width: 0px ) and ( max-width: 450px )
//     .swiper-button-prev, .swiper-button-next
//         display: none !important
    