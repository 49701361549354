@import 'variables'

.main
    &__section
        flex-direction: column !important
        &__about
            min-height: fit-content
            max-height: fit-content
            width: 100%
            position: relative
            @include content
            flex-direction: column
            margin: 0px
            background-color: $secondary-color
            &__container
                width: 90vw
                display: flex
                flex-direction: column
                justify-content: center
                align-items: center
                padding-bottom: 300px
                &__button
                    @include buttonContainer
                    max-width: 250px
                    &__hover
                        @include button
                &__group1
                    width: -webkit-fill-available
                    display: flex
                    align-items: flex-start
                    justify-content: space-between
                    margin-bottom: 50px
                    &__left
                        &__title
                            // padding: 30px 100px
                            // background: url(../img/smear.svg) no-repeat
                            background-size: contain
                            position: relative
                            margin-bottom: 65px
                            width: fit-content
                            height: fit-content
                            display: flex
                            align-items: flex-end
                            justify-content: center
                            &__img
                                width: 30vw
                                @media screen and ( max-width: 1439px )
                                    width: 35vw
                                @media screen and ( max-width: 1240px )
                                    width: 40vw
                                @media screen and ( max-width: 900px )
                                    width: 50vw
                                @media screen and ( max-width: 768px )
                                    width: 60vw
                                @media screen and ( max-width: 650px )
                                    width: 70vw
                            &__h1
                                // position: absolute
                                text-align: center
                                @include header1
                                color: $secondary-color
                                font-size: clamp(48px, calc(48px + 1vw), 24px)
                        &__points
                            margin-left: 100px
                            justify-self: right
                            &__point
                                display: flex
                                @include header3
                                // font-size: 16px
                                color: $main-color
                                margin-bottom: 30px
                                &__text
                                    font-size: 24px
                                    @media screen and ( max-width: 768px )
                                        font-size: 16px
                                &__img
                                    margin-right: 30px
                    &__right
                        align-self: center
                        width: fit-content
                        &__img
                            width: 30vw
                &__group2
                    width: 80vw
                    align-self: flex-start
                    display: flex
                    align-items: flex-start
                    justify-content: space-evenly
                    &__right
                        display: block
                        &__title
                            // padding: 40px 110px 10px
                            // background: url(../img/smear2.svg) no-repeat center
                            background-size: contain
                            position: relative
                            margin-bottom: 65px
                            width: fit-content
                            height: fit-content
                            display: flex
                            align-items: flex-end
                            justify-content: center
                            &__img
                                width: 30vw
                                @media screen and ( max-width: 1439px )
                                    width: 35vw
                                @media screen and ( max-width: 1240px )
                                    width: 40vw
                                @media screen and ( max-width: 900px )
                                    width: 50vw
                                @media screen and ( max-width: 768px )
                                    width: 60vw
                                @media screen and ( max-width: 650px )
                                    width: 70vw
                            &__h1
                                // position: absolute
                                text-align: center
                                z-index: 2
                                @include header1
                                color: $secondary-color
                                line-height: 105%
                                font-size: clamp(48px, calc(48px + 1vw), 24px)
                        &__points
                            margin-left: 100px
                            justify-self: right
                            &__point
                                display: flex
                                @include header3
                                font-size: 16px
                                color: $main-color
                                margin-bottom: 80px
                                &__text
                                    font-size: 24px
                                    @media screen and ( max-width: 768px )
                                        font-size: 16px
                                &__img
                                    margin-right: 30px
                    &__left
                        align-self: flex-end
                        width: fit-content
                        &__img
                            width: 25vw
            &__waves
                object-fit: cover
                width: 100%
                position: absolute
                bottom: 0px


