@import 'variables'

.main
    &__section
        &__forWhom
            position: relative
            @include content
            flex-direction: column
            margin: 0px
            &__waves
                // position: absolute
                object-fit: cover
                // top: -200px
                width: 100%
                width: 150%
            &__container
                z-index: 2
                width: -webkit-fill-available
                // margin: 0px 50px
                width: 90vw
                display: flex
                flex-direction: column
                align-items: center
                justify-content: center
                &__h1
                    @include header1
                    color: $secondary-color
                    margin-bottom: 30px
                    margin-top: 50px
                &__content
                    width: -webkit-fill-available
                    display: flex
                    align-items: flex-start
                    justify-content: space-evenly
                    &__info
                        padding: 30px
                        border-radius: 30px
                        background-color: $main-color
                        box-shadow: 5px 5px 10px 0px rgba(157, 162, 174, 0.9) inset, -5px -5px 10px 0px rgba(255, 255, 255, 0.9) inset, 5px -5px 10px 0px rgba(157, 162, 174, 0.2) inset, -5px 5px 10px 0px rgba(157, 162, 174, 0.2) inset, -1px -1px 2px 0px rgba(157, 162, 174, 0.5), 1px 1px 2px 0px rgba(255, 255, 255, 0.3)
                        min-width: 30vw
                        max-width: 30vw
                        margin-right: 50px
                        height: fit-content
                        max-height: 80vh
                        overflow-y: auto
                        align-self: center
                        &__kids, &__adult
                            display: flex
                            flex-direction: column
                            align-items: flex-start
                            justify-content: center
                            &__title
                                align-self: center
                                @include header3
                                margin-bottom: 40px
                            &__point
                                font-family: 'main'
                                font-size: 16px
                                font-weight: 500
                                margin-bottom: 15px
                            &__text
                                font-family: 'main'
                                font-size: 16px
                                line-height: 175%
                                font-weight: 400
                                margin-bottom: 30px
                    &__box
                        display: flex
                        flex-direction: column
                        align-items: center
                        justify-content: center
                        min-width: 40vw
                        max-width: 40vw
                        &__title
                            @include header3
                            color: $secondary-color
                            margin-bottom: 80px
                            max-width: 470px
                            text-align: center
                        &__choice
                            display: flex
                            align-items: flex-end
                            justify-content: space-evenly
                            width: 100%
                            @media (max-width: 1024px)
                                display: flex
                                flex-direction: column
                                align-items: center
                                justify-content: center
                                width: 100%
                            &__age
                                display: flex
                                flex-direction: column
                                justify-content: flex-end
                                align-items: center
                                &:nth-child(1)
                                    margin-right: 40px
                                    @media (max-width: 1024px)
                                        margin-right: 0px
                                        margin-bottom: 40px
                                &__img
                                    width: 20vw
                                    margin-bottom: 30px
                                &__button
                                    @include button
                                    color: $main-color
                                    background-color: $secondary-color