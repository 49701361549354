@import 'variables'

.main
    min-width: 100%
    max-width: 100%
    &__section
        &__rates
            width: fit-content
            display: flex
            flex-direction: column
            align-items: center
            &__gradient
                object-fit: cover
                width: 100%
            &__container
                width: 95vw
                display: flex
                justify-content: center
                flex-direction: column
                align-items: center
                margin-bottom: 40px
                &__title
                    @include header1
                    color: $secondary-color
                    text-align: center
                &__choice
                    width: 50vw
                    display: flex
                    justify-content: space-evenly
                    flex-wrap: wrap
                    &__age
                        @include link
                        @include transition
                        border: none
                        background-color: #ffffff00
                        text-transform: uppercase
                        cursor: pointer
                        margin-bottom: 25px
                        display: flex
                        flex-direction: column
                        align-items: center
                        justify-content: center
                        background-color: #ffffff00
                        padding: 10px 20px
                        border-radius: 30px
                        &:hover
                            @include transition
                            background-color: #ffc93cc2
                            // padding: 10px 20px
                            // border-radius: 30px
                            // text-decoration: underline
                        // &:nth-child(1)
                        //     margin-right: 100px
                        &.selected
                            background-color: $third-color
                            // font-weight: 500
                            // text-decoration: underline
                        // &:nth-child(2)
                        //     margin-right: 100px
                        &__span
                            font-size: 12px
                            text-decoration: none
                &__packages
                    width: 95vw
                    display: flex
                    justify-content: space-evenly
                    align-items: center
                    // overflow: hidden
                    &__age
                        display: flex
                        justify-content: space-evenly
                        align-items: flex-start
                        width: 100%
                        &__package
                            display: flex
                            flex-direction: column
                            justify-content: space-evenly
                            align-items: center
                            &__duration
                                display: flex
                                justify-content: center
                                width: 100%
                                &__time
                                    @include link
                                    // @include transition
                                    border: none
                                    background-color: #ffffff00
                                    text-transform: uppercase
                                    cursor: pointer
                                    margin-bottom: 5px
                                    display: flex
                                    flex-direction: column
                                    align-items: center
                                    padding: 5px 15px
                                    &:hover
                                        background-color: $third-color
                                        border-radius: 30px
                                    &:nth-child(1)
                                        margin-right: 15px
                                    &.selected
                                        padding: 5px 15px
                                        background-color: $third-color
                                        border-radius: 30px
                                        font-weight: 500
                            &__title
                                @include text
                                margin-bottom: 20px
                                margin-right: 50px
                                color: $secondary-color
                                font-size: 24px
                                padding: 10px 15px
                                border-radius: 30px
                                align-self: flex-end
                                background: linear-gradient(90deg, rgba(255, 201, 60, 0.00) 0%, rgba(255, 201, 60, 0.28) 100%)
                            .groupTitle
                                align-self: flex-start
                                margin-bottom: 50px
                                margin-left: 50px
                                background: linear-gradient(90deg, rgba(255, 201, 60, 0.28) 0%, rgba(255, 201, 60, 0.00) 100%)
                            &__slider
                                // width: 700px
                                width: 40vw
                                box-shadow: none
                                .swiper-pagination
                                    bottom: 30px !important
                                .swiper-button-prev
                                    content: url('../img/arrow-left-rates.svg')
                                    top: auto !important
                                    bottom: 20px !important
                                    left: 35% !important
                                    z-index: 11
                                .swiper-button-next
                                    content: url('../img/arrow-right-rates.svg')
                                    top: auto !important
                                    bottom: 20px !important
                                    right: 35% !important
                                    z-index: 11
                                &__slide
                                    padding: 15px 0px 40px 0px
                                    display: flex !important
                                    justify-content: center
                                    align-items: center
                                    margin-bottom: 30px
                                    // width: 700px!important
                                    &.swiper-slide-shadow
                                        display: none !important
                                    &__card
                                        position: relative
                                        display: flex
                                        flex-direction: column
                                        justify-content: space-evenly
                                        align-items: center
                                        width: fit-content
                                        min-height: 600px
                                        max-height: 600px
                                        padding: 50px 40px 40px 30px
                                        border-radius: 40px
                                        border: 1px solid #272E48
                                        background: #E0E7F8
                                        box-shadow: none
                                        color: $secondary-color
                                        @media (max-width: 600px)
                                            padding: 50px 30px 30px 30px
                                            min-height: 630px
                                            max-height: 630px
                                        @media (max-width: 430px)
                                            padding: 40px 10px 10px 10px
                                            min-height: 670px
                                            max-height: 670px
                                        &__h3
                                            @include text
                                            // margin-bottom: 10px
                                        &__title
                                            @include header2
                                            font-size: 30px
                                            margin-bottom: 20px
                                        &__format
                                            @include text
                                            margin-bottom: 20px
                                            font-size: 16px
                                        &__img
                                            width: 150px
                                            margin-bottom: 20px
                                        &__features
                                            width: 320px
                                            margin-bottom: 10px
                                            &__point
                                                @include text
                                                font-size: 14px
                                                font-weight: 400
                                                margin-bottom: 10px
                                        &__price
                                            display: flex
                                            flex-direction: column
                                            align-items: center
                                            justify-content: center
                                            @include header2
                                            font-size: 30px
                                            margin-bottom: 20px
                                            &__note
                                                @include text
                                                text-align: center
                                                font-weight: 300
                                                font-size: 12px
                                        &__button
                                            @include button
                                            background-color: $secondary-color
                                            color: $main-color

// .swiper-button-prev
//     content: url(../img/arrow_left.svg)

// .swiper-button-next
//     content: url(../img/arrow_right.svg)

// .swiper-button-prev, .swiper-button-next
//     top: auto !important
//     bottom: 10px !important
