@import 'variables'

.main
    &__section
        &__diplomas
            position: relative
            @include content
            flex-direction: column
            margin: 0px
            padding-top: 50px
            margin-bottom: 50px
            &__title
                @include header1
                text-align: center
                color: $secondary-color
            &__container
                min-height: fit-content
                max-height: fit-content
                display: flex
                justify-content: space-evenly
                align-items: flex-start
                flex-wrap: wrap
                min-width: calc( 100vw - 200px )
                max-width: calc( 100vw - 200px )
                &__slider
                    display: flex !important
                    justify-content: center
                    align-items: center
                    width: 100%
                    &.swiper-wrapper
                        align-items: center
                    &__slide
                        display: flex !important
                        flex-direction: column
                        justify-content: center
                        align-items: center
                        margin: auto
                        margin-bottom: 40px
                        &__img
                            border: 1px solid #474747
                            position: relative
                            width: 80%
                            max-height: 450px
                            margin-bottom: 10px
                            margin-top: 30px
                            border-radius: 30px
                            box-shadow: 7px 7px 4px 0px rgba(0, 0, 0, 0.25)
            &__popUp
                margin: 0px auto
                display: flex !important
                justify-content: center
                align-items: center
                position: relative
                &__modal
                    position: relative
                    width: 90vw
                    height: 90vh
                    margin: 0px
                    display: flex !important
                    justify-content: center
                    align-items: center
                    &__content
                        width: fit-content
                        height: fit-content
                        &__img
                            width: 100%
                            border-radius: 30px