@font-face {
  font-family: "main";
  src: url(../fonts/Montserrat.ttf);
}
@font-face {
  font-family: "display";
  src: url(../fonts/Gropled-Bold.otf);
}
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #E0E7F8;
}

::-webkit-scrollbar-thumb {
  background-color: #272E48;
  border-radius: 10px;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
    transform: translateY(0);
  }
  10% {
    transform: translateY(-40px);
    transform: translateY(-40px);
  }
  20% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  40% {
    transform: translateY(-30px);
    transform: translateY(-30px);
  }
  50% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  70% {
    transform: translateY(-10px);
    transform: translateY(-10px);
  }
  80% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
}
.header {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 100%;
  position: sticky;
  top: 0px;
  z-index: 20;
  background-color: #272E48;
  box-shadow: 0px 7px 10px 0px rgba(0, 0, 0, 0.7);
}
.header__logo {
  padding: 0px 50px;
  max-height: 80px;
  justify-self: flex-start;
  cursor: pointer;
}
@media (max-width: 700px) {
  .header__logo {
    padding: 0px 10px;
  }
}
.header__content {
  display: flex;
  align-items: center;
  margin-right: 50px;
  height: 80px;
}
.header__content__burger {
  display: none;
}
@media (max-width: 1024px) {
  .header__content__burger {
    display: block;
    cursor: pointer;
  }
}
.header__content__nav {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  align-self: flex-start;
}
@media (max-width: 1024px) {
  .header__content__nav {
    display: none;
  }
}
.header__content__nav__a {
  text-decoration: none;
  text-transform: uppercase;
  font-family: "main";
  font-size: 16px;
  font-weight: 400;
  font-size: clamp(9px, 0px + 0.9vw, 16px);
  color: #E0E7F8;
  padding-top: 31px;
  margin-right: 40px;
  cursor: pointer;
}
.header__content__nav__a:hover {
  border-top: 5px solid #E0E7F8;
  text-transform: uppercase;
  font-family: "main";
  font-weight: 400;
  color: #E0E7F8;
  padding-top: 26px;
  text-decoration: none;
  height: 54px;
}
.header__content__button {
  z-index: 2;
  cursor: pointer;
  width: fit-content;
  height: fit-content;
  border-radius: 30px;
  box-shadow: 4px 4px 7px 0px rgba(0, 0, 0, 0.61);
  margin: 10px;
  transition: all 0.5s ease-out;
}
.header__content__button:hover {
  box-shadow: none;
  transition: all 0.5s ease-out;
}
.header__content__button__hover {
  cursor: pointer;
  box-shadow: none;
  min-height: 46px;
  width: fit-content;
  padding: 10px 30px;
  color: #272E48;
  background-color: #E0E7F8;
  border-radius: 30px;
  border: none;
  font-family: "main";
  font-size: 16px;
  text-transform: uppercase;
  transition: all 0.5s ease-out;
}
.header__content__button__hover:hover {
  box-shadow: inset 9px 9px 10px 0px rgba(0, 0, 0, 0.61);
  transition: all 0.5s ease-out;
}
@media (max-width: 700px) {
  .header__content__button__hover {
    padding: 5px 10px;
    font-size: 14px;
  }
}
@media (max-width: 425px) {
  .header__content__button__hover {
    font-size: 12px;
    min-height: 35px;
  }
}

.header__content__nav__dropdown {
  position: relative;
  display: none;
}

.active .header__content__nav__dropdown-content {
  display: flex;
  flex-direction: column;
  padding: 0px;
  position: absolute;
  top: 100%;
  right: 50px;
  background-color: #272E48;
  padding: 10px;
  z-index: 1;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  box-shadow: 0px 7px 10px 0px rgba(0, 0, 0, 0.7);
}
@media (min-width: 1024px) {
  .active .header__content__nav__dropdown-content {
    display: none;
  }
}
.active .header__content__nav__dropdown-content__a {
  padding: 10px 20px;
  text-decoration: none;
  font-family: "main";
  font-size: 16px;
  font-weight: 400;
  color: #E0E7F8;
}
.active .header__content__nav__dropdown-content__a:hover {
  color: #FFC93C;
}

.header__content__nav__dropdown-content {
  display: none;
}

@font-face {
  font-family: "main";
  src: url(../fonts/Montserrat.ttf);
}
@font-face {
  font-family: "display";
  src: url(../fonts/Gropled-Bold.otf);
}
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #E0E7F8;
}

::-webkit-scrollbar-thumb {
  background-color: #272E48;
  border-radius: 10px;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
    transform: translateY(0);
  }
  10% {
    transform: translateY(-40px);
    transform: translateY(-40px);
  }
  20% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  40% {
    transform: translateY(-30px);
    transform: translateY(-30px);
  }
  50% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  70% {
    transform: translateY(-10px);
    transform: translateY(-10px);
  }
  80% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
}
.main {
  min-width: 100%;
  max-width: 100%;
}
.main__up {
  position: fixed;
  right: 20px;
  bottom: 100px;
  width: 65px;
  z-index: 100;
  cursor: pointer;
}
.main__choice__modal__layout {
  border-radius: 30px;
  max-width: 90vw !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main__choice__modal__layout__content {
  position: relative;
  width: fit-content;
  max-width: 70vw;
  display: flex;
  flex-direction: column;
  padding: 15px;
}
@media (max-width: 768px) {
  .main__choice__modal__layout__content {
    max-width: 90vw;
  }
}
.main__choice__modal__layout__content__title {
  font-family: "main";
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px;
  color: #272E48;
}
@media (max-width: 670px) {
  .main__choice__modal__layout__content__title {
    font-size: 20px;
  }
}
@media (max-width: 550px) {
  .main__choice__modal__layout__content__title {
    font-size: 18px;
    padding: 0px 15px;
  }
}
.main__choice__modal__layout__content__a {
  font-family: "main";
  font-size: 16px;
  font-weight: 400;
  color: #272E48;
  text-decoration: none;
  text-align: center;
  margin-bottom: 10px;
  transition: all 0.5s ease-out;
}
.main__choice__modal__layout__content__a:hover {
  text-decoration: underline;
  color: #c18f0d;
  transition: all 0.5s ease-out;
}
.main__choice__modal__layout__content__p {
  font-family: "main";
  font-size: 16px;
  font-weight: 400;
  color: #272E48;
  text-decoration: none;
  text-align: start;
  padding: 0px 50px;
  margin-bottom: 10px;
  line-height: 200%;
  transition: all 0.5s ease-out;
}
.main__choice__modal__layout__content__sales {
  padding: 15px;
}
@media (max-width: 768px) {
  .main__choice__modal__layout__content__sales {
    padding: 5px;
  }
}
.main__choice__modal__layout__content__sales__note {
  font-family: "main";
  margin-bottom: 10px;
  font-size: 14px;
  color: rgba(65, 65, 65, 0.7137254902);
  color: rgba(255, 60, 0, 0.8470588235);
  font-weight: 400;
  text-decoration: none;
  text-align: end;
}
.main__choice__modal__layout__content__sales__sale {
  margin-bottom: 20px;
}
.main__choice__modal__layout__content__sales__sale__title {
  font-family: "main";
  font-size: 24px;
  font-weight: 500;
  color: #272E48;
  font-size: 20px;
  margin-bottom: 10px;
}
@media (max-width: 768px) {
  .main__choice__modal__layout__content__sales__sale__title {
    font-size: 16px;
  }
}
.main__choice__modal__layout__content__sales__sale__text {
  font-family: "main";
  font-size: 18px;
  font-weight: 500;
  font-size: 16px;
  line-height: 175%;
  color: #272E48;
  margin-left: 10px;
}
@media (max-width: 768px) {
  .main__choice__modal__layout__content__sales__sale__text {
    font-size: 14px;
  }
}
.main__section {
  background-attachment: fixed;
  background-size: cover;
  min-height: fit-content;
  max-height: fit-content;
  padding-bottom: 0px;
  position: relative;
  overflow: hidden;
}
.main__section__gradient {
  position: absolute;
  top: 0px;
  object-fit: cover;
  width: 100%;
}
.main__section__greeting {
  width: 100vw;
  position: relative;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 80px);
  max-height: calc(100vh - 80px);
  flex-direction: column;
  background-color: #272E48;
  overflow: hidden;
  min-height: fit-content;
  max-height: fit-content;
}
.main__section__greeting__china1 {
  position: absolute;
  top: 0px;
  width: 10vw;
}
@media (max-width: 1024px) {
  .main__section__greeting__china1 {
    width: 20vw;
  }
}
.main__section__greeting__china2 {
  position: absolute;
  top: 0px;
  width: 20vw;
  margin-left: 400px;
}
@media (max-width: 1024px) {
  .main__section__greeting__china2 {
    width: 40vw;
  }
}
.main__section__greeting__waves {
  position: absolute;
  object-fit: cover;
  bottom: 0px;
  width: 100%;
}
.main__section__greeting__content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90vw;
  justify-content: flex-start;
  min-height: 55rem;
}
@media (max-width: 425px) {
  .main__section__greeting__content {
    max-height: fit-content;
    min-height: 45rem;
  }
}
.main__section__greeting__content__number {
  position: absolute;
  top: 20px;
  right: 0px;
  font-family: "main";
  font-size: 16px;
  font-weight: 400;
  color: #E0E7F8;
  margin-bottom: 20px;
  font-size: 24px;
  transition: all 0.5s ease-out;
}
.main__section__greeting__content__number:hover {
  transition: all 0.5s ease-out;
  color: #FFC93C;
}
@media (max-width: 1000px) {
  .main__section__greeting__content__number {
    left: 0px;
  }
}
.main__section__greeting__content__left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  min-width: 50vw;
  z-index: 2;
}
.main__section__greeting__content__left__h1 {
  font-family: "display";
  line-height: 125%;
  font-size: 60px;
  font-weight: 700;
  color: #E0E7F8;
  width: 50vw;
  align-self: flex-start;
  margin-bottom: 20px;
}
@media (min-width: 0px) and (max-width: 650px) {
  .main__section__greeting__content__left__h1 {
    font-size: 48px;
  }
}
@media (max-width: 1024px) {
  .main__section__greeting__content__left__h1 {
    font-size: 48px;
  }
}
@media (max-width: 768px) {
  .main__section__greeting__content__left__h1 {
    font-size: 36px;
  }
}
@media (max-width: 425px) {
  .main__section__greeting__content__left__h1 {
    font-size: 30px;
  }
}
.main__section__greeting__content__left__p {
  font-family: "main";
  font-size: 18px;
  font-weight: 500;
  color: #E0E7F8;
  align-self: flex-start;
  margin-bottom: 20px;
  font-size: 24px;
}
@media (max-width: 768px) {
  .main__section__greeting__content__left__p {
    font-size: 20px;
  }
}
@media (max-width: 650px) {
  .main__section__greeting__content__left__p {
    font-size: 18px;
  }
}
@media (max-width: 400px) {
  .main__section__greeting__content__left__p {
    font-size: 16px;
  }
}
.main__section__greeting__content__left__button {
  align-self: flex-end;
  z-index: 2;
  cursor: pointer;
  width: fit-content;
  height: fit-content;
  border-radius: 30px;
  box-shadow: 4px 4px 7px 0px rgba(0, 0, 0, 0.61);
  margin: 10px;
  transition: all 0.5s ease-out;
  max-width: 250px;
}
.main__section__greeting__content__left__button:hover {
  box-shadow: none;
  transition: all 0.5s ease-out;
}
.main__section__greeting__content__left__button__hover {
  cursor: pointer;
  box-shadow: none;
  min-height: 46px;
  width: fit-content;
  padding: 10px 30px;
  color: #272E48;
  background-color: #E0E7F8;
  border-radius: 30px;
  border: none;
  font-family: "main";
  font-size: 16px;
  text-transform: uppercase;
  transition: all 0.5s ease-out;
}
.main__section__greeting__content__left__button__hover:hover {
  box-shadow: inset 9px 9px 10px 0px rgba(0, 0, 0, 0.61);
  transition: all 0.5s ease-out;
}
.main__section__greeting__content__left__choiceButton {
  margin-bottom: 100px;
  align-self: flex-start;
  max-width: 250px;
  z-index: 2;
  cursor: pointer;
  width: fit-content;
  height: fit-content;
  border-radius: 30px;
  box-shadow: 4px 4px 7px 0px rgba(0, 0, 0, 0.61);
  margin: 10px;
  transition: all 0.5s ease-out;
}
.main__section__greeting__content__left__choiceButton:hover {
  box-shadow: none;
  transition: all 0.5s ease-out;
}
.main__section__greeting__content__left__choiceButton__hover {
  cursor: pointer;
  transition: all 0.5s ease-out;
  border: none;
  cursor: pointer;
  box-shadow: none;
  min-height: 46px;
  width: fit-content;
  padding: 10px 30px;
  color: #272E48;
  background-color: #E0E7F8;
  border-radius: 30px;
  border: none;
  font-family: "main";
  font-size: 16px;
  text-transform: uppercase;
  transition: all 0.5s ease-out;
  background-color: rgba(255, 255, 255, 0);
  text-transform: none;
  font-family: "main";
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  color: #FFC93C;
}
.main__section__greeting__content__left__choiceButton__hover:hover {
  box-shadow: inset 9px 9px 10px 0px rgba(0, 0, 0, 0.61);
  transition: all 0.5s ease-out;
}
.main__section__greeting__content__left__choiceButton__hover:hover {
  transition: all 0.5s ease-out;
}
.main__section__greeting__content__right {
  position: relative;
  width: 100%;
  height: 100%;
}
.main__section__greeting__content__right__china1 {
  position: absolute;
  top: 0px;
  width: 100px;
}

.swiper-slide-shadow {
  display: none !important;
}

.swiper-button-prev {
  content: url(../img/arrow_left.svg);
  left: 0px !important;
}

.swiper-button-next {
  content: url(../img/arrow_right.svg);
  right: 0px !important;
}

.swiper-pagination-bullet {
  background-color: rgba(35, 32, 32, 0.3) !important;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.25) inset;
}

.swiper-pagination-bullet-active {
  background-color: #FFCB03 !important;
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.25) inset;
}

.hystmodal__close:focus {
  outline: none !important;
}

.hystmodal__window {
  background-color: rgba(255, 255, 255, 0) !important;
}

.sliderComments {
  max-width: 70vw;
  min-width: 70vw;
}

@font-face {
  font-family: "main";
  src: url(../fonts/Montserrat.ttf);
}
@font-face {
  font-family: "display";
  src: url(../fonts/Gropled-Bold.otf);
}
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #E0E7F8;
}

::-webkit-scrollbar-thumb {
  background-color: #272E48;
  border-radius: 10px;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
    transform: translateY(0);
  }
  10% {
    transform: translateY(-40px);
    transform: translateY(-40px);
  }
  20% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  40% {
    transform: translateY(-30px);
    transform: translateY(-30px);
  }
  50% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  70% {
    transform: translateY(-10px);
    transform: translateY(-10px);
  }
  80% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
}
.main {
  min-width: 100%;
  max-width: 100%;
}
.main__section__advantages {
  position: relative;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0px;
  padding-top: 50px;
}
.main__section__advantages__h1 {
  font-family: "display";
  line-height: 125%;
  font-size: 60px;
  font-weight: 700;
  color: #272E48;
  margin-bottom: 50px;
}
@media (min-width: 0px) and (max-width: 650px) {
  .main__section__advantages__h1 {
    font-size: 48px;
  }
}
.main__section__advantages__container {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  min-width: 90vw;
  max-width: 90vw;
}
.main__section__advantages__container__slider {
  width: 100%;
}
.main__section__advantages__container__slider .swiper-button-prev {
  content: url(../img/arrow_left.svg);
}
.main__section__advantages__container__slider .swiper-button-next {
  content: url(../img/arrow_right.svg);
}
.main__section__advantages__container__slider__slide {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 0px 0px 40px 0px;
}
.main__section__advantages__container__slider__slide__img {
  margin-bottom: 20px;
}
.main__section__advantages__container__slider__slide__name {
  font-family: "main";
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  min-width: 350px;
  max-width: 350px;
}

@font-face {
  font-family: "main";
  src: url(../fonts/Montserrat.ttf);
}
@font-face {
  font-family: "display";
  src: url(../fonts/Gropled-Bold.otf);
}
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #E0E7F8;
}

::-webkit-scrollbar-thumb {
  background-color: #272E48;
  border-radius: 10px;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
    transform: translateY(0);
  }
  10% {
    transform: translateY(-40px);
    transform: translateY(-40px);
  }
  20% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  40% {
    transform: translateY(-30px);
    transform: translateY(-30px);
  }
  50% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  70% {
    transform: translateY(-10px);
    transform: translateY(-10px);
  }
  80% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
}
.main__section__forWhom {
  position: relative;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0px;
}
.main__section__forWhom__waves {
  object-fit: cover;
  width: 100%;
  width: 150%;
}
.main__section__forWhom__container {
  z-index: 2;
  width: -webkit-fill-available;
  width: 90vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.main__section__forWhom__container__h1 {
  font-family: "display";
  line-height: 125%;
  font-size: 60px;
  font-weight: 700;
  color: #272E48;
  margin-bottom: 30px;
  margin-top: 50px;
}
@media (min-width: 0px) and (max-width: 650px) {
  .main__section__forWhom__container__h1 {
    font-size: 48px;
  }
}
.main__section__forWhom__container__content {
  width: -webkit-fill-available;
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
}
.main__section__forWhom__container__content__info {
  padding: 30px;
  border-radius: 30px;
  background-color: #E0E7F8;
  box-shadow: 5px 5px 10px 0px rgba(157, 162, 174, 0.9) inset, -5px -5px 10px 0px rgba(255, 255, 255, 0.9) inset, 5px -5px 10px 0px rgba(157, 162, 174, 0.2) inset, -5px 5px 10px 0px rgba(157, 162, 174, 0.2) inset, -1px -1px 2px 0px rgba(157, 162, 174, 0.5), 1px 1px 2px 0px rgba(255, 255, 255, 0.3);
  min-width: 30vw;
  max-width: 30vw;
  margin-right: 50px;
  height: fit-content;
  max-height: 80vh;
  overflow-y: auto;
  align-self: center;
}
.main__section__forWhom__container__content__info__kids, .main__section__forWhom__container__content__info__adult {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.main__section__forWhom__container__content__info__kids__title, .main__section__forWhom__container__content__info__adult__title {
  align-self: center;
  font-family: "main";
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 40px;
}
.main__section__forWhom__container__content__info__kids__point, .main__section__forWhom__container__content__info__adult__point {
  font-family: "main";
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 15px;
}
.main__section__forWhom__container__content__info__kids__text, .main__section__forWhom__container__content__info__adult__text {
  font-family: "main";
  font-size: 16px;
  line-height: 175%;
  font-weight: 400;
  margin-bottom: 30px;
}
.main__section__forWhom__container__content__box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 40vw;
  max-width: 40vw;
}
.main__section__forWhom__container__content__box__title {
  font-family: "main";
  font-size: 24px;
  font-weight: 500;
  color: #272E48;
  margin-bottom: 80px;
  max-width: 470px;
  text-align: center;
}
.main__section__forWhom__container__content__box__choice {
  display: flex;
  align-items: flex-end;
  justify-content: space-evenly;
  width: 100%;
}
@media (max-width: 1024px) {
  .main__section__forWhom__container__content__box__choice {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}
.main__section__forWhom__container__content__box__choice__age {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
.main__section__forWhom__container__content__box__choice__age:nth-child(1) {
  margin-right: 40px;
}
@media (max-width: 1024px) {
  .main__section__forWhom__container__content__box__choice__age:nth-child(1) {
    margin-right: 0px;
    margin-bottom: 40px;
  }
}
.main__section__forWhom__container__content__box__choice__age__img {
  width: 20vw;
  margin-bottom: 30px;
}
.main__section__forWhom__container__content__box__choice__age__button {
  cursor: pointer;
  box-shadow: none;
  min-height: 46px;
  width: fit-content;
  padding: 10px 30px;
  color: #272E48;
  background-color: #E0E7F8;
  border-radius: 30px;
  border: none;
  font-family: "main";
  font-size: 16px;
  text-transform: uppercase;
  transition: all 0.5s ease-out;
  color: #E0E7F8;
  background-color: #272E48;
}
.main__section__forWhom__container__content__box__choice__age__button:hover {
  box-shadow: inset 9px 9px 10px 0px rgba(0, 0, 0, 0.61);
  transition: all 0.5s ease-out;
}

@font-face {
  font-family: "main";
  src: url(../fonts/Montserrat.ttf);
}
@font-face {
  font-family: "display";
  src: url(../fonts/Gropled-Bold.otf);
}
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #E0E7F8;
}

::-webkit-scrollbar-thumb {
  background-color: #272E48;
  border-radius: 10px;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
    transform: translateY(0);
  }
  10% {
    transform: translateY(-40px);
    transform: translateY(-40px);
  }
  20% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  40% {
    transform: translateY(-30px);
    transform: translateY(-30px);
  }
  50% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  70% {
    transform: translateY(-10px);
    transform: translateY(-10px);
  }
  80% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
}
.main__section {
  flex-direction: column !important;
}
.main__section__about {
  min-height: fit-content;
  max-height: fit-content;
  width: 100%;
  position: relative;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0px;
  background-color: #272E48;
}
.main__section__about__container {
  width: 90vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 300px;
}
.main__section__about__container__button {
  z-index: 2;
  cursor: pointer;
  width: fit-content;
  height: fit-content;
  border-radius: 30px;
  box-shadow: 4px 4px 7px 0px rgba(0, 0, 0, 0.61);
  margin: 10px;
  transition: all 0.5s ease-out;
  max-width: 250px;
}
.main__section__about__container__button:hover {
  box-shadow: none;
  transition: all 0.5s ease-out;
}
.main__section__about__container__button__hover {
  cursor: pointer;
  box-shadow: none;
  min-height: 46px;
  width: fit-content;
  padding: 10px 30px;
  color: #272E48;
  background-color: #E0E7F8;
  border-radius: 30px;
  border: none;
  font-family: "main";
  font-size: 16px;
  text-transform: uppercase;
  transition: all 0.5s ease-out;
}
.main__section__about__container__button__hover:hover {
  box-shadow: inset 9px 9px 10px 0px rgba(0, 0, 0, 0.61);
  transition: all 0.5s ease-out;
}
.main__section__about__container__group1 {
  width: -webkit-fill-available;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 50px;
}
.main__section__about__container__group1__left__title {
  background-size: contain;
  position: relative;
  margin-bottom: 65px;
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.main__section__about__container__group1__left__title__img {
  width: 30vw;
}
@media screen and (max-width: 1439px) {
  .main__section__about__container__group1__left__title__img {
    width: 35vw;
  }
}
@media screen and (max-width: 1240px) {
  .main__section__about__container__group1__left__title__img {
    width: 40vw;
  }
}
@media screen and (max-width: 900px) {
  .main__section__about__container__group1__left__title__img {
    width: 50vw;
  }
}
@media screen and (max-width: 768px) {
  .main__section__about__container__group1__left__title__img {
    width: 60vw;
  }
}
@media screen and (max-width: 650px) {
  .main__section__about__container__group1__left__title__img {
    width: 70vw;
  }
}
.main__section__about__container__group1__left__title__h1 {
  text-align: center;
  font-family: "display";
  line-height: 125%;
  font-size: 60px;
  font-weight: 700;
  color: #272E48;
  font-size: clamp(48px, 48px + 1vw, 24px);
}
@media (min-width: 0px) and (max-width: 650px) {
  .main__section__about__container__group1__left__title__h1 {
    font-size: 48px;
  }
}
.main__section__about__container__group1__left__points {
  margin-left: 100px;
  justify-self: right;
}
.main__section__about__container__group1__left__points__point {
  display: flex;
  font-family: "main";
  font-size: 24px;
  font-weight: 500;
  color: #E0E7F8;
  margin-bottom: 30px;
}
.main__section__about__container__group1__left__points__point__text {
  font-size: 24px;
}
@media screen and (max-width: 768px) {
  .main__section__about__container__group1__left__points__point__text {
    font-size: 16px;
  }
}
.main__section__about__container__group1__left__points__point__img {
  margin-right: 30px;
}
.main__section__about__container__group1__right {
  align-self: center;
  width: fit-content;
}
.main__section__about__container__group1__right__img {
  width: 30vw;
}
.main__section__about__container__group2 {
  width: 80vw;
  align-self: flex-start;
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
}
.main__section__about__container__group2__right {
  display: block;
}
.main__section__about__container__group2__right__title {
  background-size: contain;
  position: relative;
  margin-bottom: 65px;
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.main__section__about__container__group2__right__title__img {
  width: 30vw;
}
@media screen and (max-width: 1439px) {
  .main__section__about__container__group2__right__title__img {
    width: 35vw;
  }
}
@media screen and (max-width: 1240px) {
  .main__section__about__container__group2__right__title__img {
    width: 40vw;
  }
}
@media screen and (max-width: 900px) {
  .main__section__about__container__group2__right__title__img {
    width: 50vw;
  }
}
@media screen and (max-width: 768px) {
  .main__section__about__container__group2__right__title__img {
    width: 60vw;
  }
}
@media screen and (max-width: 650px) {
  .main__section__about__container__group2__right__title__img {
    width: 70vw;
  }
}
.main__section__about__container__group2__right__title__h1 {
  text-align: center;
  z-index: 2;
  font-family: "display";
  line-height: 125%;
  font-size: 60px;
  font-weight: 700;
  color: #272E48;
  line-height: 105%;
  font-size: clamp(48px, 48px + 1vw, 24px);
}
@media (min-width: 0px) and (max-width: 650px) {
  .main__section__about__container__group2__right__title__h1 {
    font-size: 48px;
  }
}
.main__section__about__container__group2__right__points {
  margin-left: 100px;
  justify-self: right;
}
.main__section__about__container__group2__right__points__point {
  display: flex;
  font-family: "main";
  font-size: 24px;
  font-weight: 500;
  font-size: 16px;
  color: #E0E7F8;
  margin-bottom: 80px;
}
.main__section__about__container__group2__right__points__point__text {
  font-size: 24px;
}
@media screen and (max-width: 768px) {
  .main__section__about__container__group2__right__points__point__text {
    font-size: 16px;
  }
}
.main__section__about__container__group2__right__points__point__img {
  margin-right: 30px;
}
.main__section__about__container__group2__left {
  align-self: flex-end;
  width: fit-content;
}
.main__section__about__container__group2__left__img {
  width: 25vw;
}
.main__section__about__waves {
  object-fit: cover;
  width: 100%;
  position: absolute;
  bottom: 0px;
}

@font-face {
  font-family: "main";
  src: url(../fonts/Montserrat.ttf);
}
@font-face {
  font-family: "display";
  src: url(../fonts/Gropled-Bold.otf);
}
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #E0E7F8;
}

::-webkit-scrollbar-thumb {
  background-color: #272E48;
  border-radius: 10px;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
    transform: translateY(0);
  }
  10% {
    transform: translateY(-40px);
    transform: translateY(-40px);
  }
  20% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  40% {
    transform: translateY(-30px);
    transform: translateY(-30px);
  }
  50% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  70% {
    transform: translateY(-10px);
    transform: translateY(-10px);
  }
  80% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
}
.main__section__results {
  min-height: fit-content;
  max-height: fit-content;
  width: 100%;
  position: relative;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0px;
  background-color: #E0E7F8;
}
.main__section__results__container {
  width: 90vw;
  margin: 50px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.main__section__results__container__title {
  font-family: "main";
  font-size: 24px;
  font-weight: 500;
  font-weight: 600;
  max-width: 90vw;
  color: #272E48;
  margin-bottom: 40px;
  align-self: flex-start;
}
.main__section__results__container__button {
  z-index: 2;
  cursor: pointer;
  width: fit-content;
  height: fit-content;
  border-radius: 30px;
  box-shadow: 4px 4px 7px 0px rgba(0, 0, 0, 0.61);
  margin: 10px;
  transition: all 0.5s ease-out;
  max-width: 250px;
}
.main__section__results__container__button:hover {
  box-shadow: none;
  transition: all 0.5s ease-out;
}
.main__section__results__container__button__hover {
  cursor: pointer;
  box-shadow: none;
  min-height: 46px;
  width: fit-content;
  padding: 10px 30px;
  color: #272E48;
  background-color: #E0E7F8;
  border-radius: 30px;
  border: none;
  font-family: "main";
  font-size: 16px;
  text-transform: uppercase;
  transition: all 0.5s ease-out;
  color: #E0E7F8;
  background-color: #272E48;
}
.main__section__results__container__button__hover:hover {
  box-shadow: inset 9px 9px 10px 0px rgba(0, 0, 0, 0.61);
  transition: all 0.5s ease-out;
}
.main__section__results__container__content {
  margin-bottom: 30px;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
}
.main__section__results__container__content__points {
  width: 50vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 40px;
}
.main__section__results__container__content__points__point {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
.main__section__results__container__content__points__point__img {
  width: 45px;
  margin-right: 30px;
}
.main__section__results__container__content__points__point__text {
  font-family: "main";
  font-size: 18px;
  font-weight: 500;
}
.main__section__results__container__content__img {
  align-self: flex-end;
  width: 30vw;
}

@font-face {
  font-family: "main";
  src: url(../fonts/Montserrat.ttf);
}
@font-face {
  font-family: "display";
  src: url(../fonts/Gropled-Bold.otf);
}
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #E0E7F8;
}

::-webkit-scrollbar-thumb {
  background-color: #272E48;
  border-radius: 10px;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
    transform: translateY(0);
  }
  10% {
    transform: translateY(-40px);
    transform: translateY(-40px);
  }
  20% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  40% {
    transform: translateY(-30px);
    transform: translateY(-30px);
  }
  50% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  70% {
    transform: translateY(-10px);
    transform: translateY(-10px);
  }
  80% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
}
.main {
  min-width: 100%;
  max-width: 100%;
}
.main__section__rates {
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.main__section__rates__gradient {
  object-fit: cover;
  width: 100%;
}
.main__section__rates__container {
  width: 95vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
}
.main__section__rates__container__title {
  font-family: "display";
  line-height: 125%;
  font-size: 60px;
  font-weight: 700;
  color: #272E48;
  text-align: center;
}
@media (min-width: 0px) and (max-width: 650px) {
  .main__section__rates__container__title {
    font-size: 48px;
  }
}
.main__section__rates__container__choice {
  width: 50vw;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.main__section__rates__container__choice__age {
  font-family: "main";
  font-size: 16px;
  font-weight: 400;
  transition: all 0.5s ease-out;
  border: none;
  background-color: rgba(255, 255, 255, 0);
  text-transform: uppercase;
  cursor: pointer;
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0);
  padding: 10px 20px;
  border-radius: 30px;
}
.main__section__rates__container__choice__age:hover {
  transition: all 0.5s ease-out;
  background-color: rgba(255, 201, 60, 0.7607843137);
}
.main__section__rates__container__choice__age.selected {
  background-color: #FFC93C;
}
.main__section__rates__container__choice__age__span {
  font-size: 12px;
  text-decoration: none;
}
.main__section__rates__container__packages {
  width: 95vw;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.main__section__rates__container__packages__age {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  width: 100%;
}
.main__section__rates__container__packages__age__package {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.main__section__rates__container__packages__age__package__duration {
  display: flex;
  justify-content: center;
  width: 100%;
}
.main__section__rates__container__packages__age__package__duration__time {
  font-family: "main";
  font-size: 16px;
  font-weight: 400;
  border: none;
  background-color: rgba(255, 255, 255, 0);
  text-transform: uppercase;
  cursor: pointer;
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px 15px;
}
.main__section__rates__container__packages__age__package__duration__time:hover {
  background-color: #FFC93C;
  border-radius: 30px;
}
.main__section__rates__container__packages__age__package__duration__time:nth-child(1) {
  margin-right: 15px;
}
.main__section__rates__container__packages__age__package__duration__time.selected {
  padding: 5px 15px;
  background-color: #FFC93C;
  border-radius: 30px;
  font-weight: 500;
}
.main__section__rates__container__packages__age__package__title {
  font-family: "main";
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
  margin-right: 50px;
  color: #272E48;
  font-size: 24px;
  padding: 10px 15px;
  border-radius: 30px;
  align-self: flex-end;
  background: linear-gradient(90deg, rgba(255, 201, 60, 0) 0%, rgba(255, 201, 60, 0.28) 100%);
}
.main__section__rates__container__packages__age__package .groupTitle {
  align-self: flex-start;
  margin-bottom: 50px;
  margin-left: 50px;
  background: linear-gradient(90deg, rgba(255, 201, 60, 0.28) 0%, rgba(255, 201, 60, 0) 100%);
}
.main__section__rates__container__packages__age__package__slider {
  width: 40vw;
  box-shadow: none;
}
.main__section__rates__container__packages__age__package__slider .swiper-pagination {
  bottom: 30px !important;
}
.main__section__rates__container__packages__age__package__slider .swiper-button-prev {
  content: url("../img/arrow-left-rates.svg");
  top: auto !important;
  bottom: 20px !important;
  left: 35% !important;
  z-index: 11;
}
.main__section__rates__container__packages__age__package__slider .swiper-button-next {
  content: url("../img/arrow-right-rates.svg");
  top: auto !important;
  bottom: 20px !important;
  right: 35% !important;
  z-index: 11;
}
.main__section__rates__container__packages__age__package__slider__slide {
  padding: 15px 0px 40px 0px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}
.main__section__rates__container__packages__age__package__slider__slide.swiper-slide-shadow {
  display: none !important;
}
.main__section__rates__container__packages__age__package__slider__slide__card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: fit-content;
  min-height: 600px;
  max-height: 600px;
  padding: 50px 40px 40px 30px;
  border-radius: 40px;
  border: 1px solid #272E48;
  background: #E0E7F8;
  box-shadow: none;
  color: #272E48;
}
@media (max-width: 600px) {
  .main__section__rates__container__packages__age__package__slider__slide__card {
    padding: 50px 30px 30px 30px;
    min-height: 630px;
    max-height: 630px;
  }
}
@media (max-width: 430px) {
  .main__section__rates__container__packages__age__package__slider__slide__card {
    padding: 40px 10px 10px 10px;
    min-height: 670px;
    max-height: 670px;
  }
}
.main__section__rates__container__packages__age__package__slider__slide__card__h3 {
  font-family: "main";
  font-size: 18px;
  font-weight: 500;
}
.main__section__rates__container__packages__age__package__slider__slide__card__title {
  font-family: "main";
  font-size: 36px;
  font-weight: 600;
  font-size: 30px;
  margin-bottom: 20px;
}
.main__section__rates__container__packages__age__package__slider__slide__card__format {
  font-family: "main";
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
  font-size: 16px;
}
.main__section__rates__container__packages__age__package__slider__slide__card__img {
  width: 150px;
  margin-bottom: 20px;
}
.main__section__rates__container__packages__age__package__slider__slide__card__features {
  width: 320px;
  margin-bottom: 10px;
}
.main__section__rates__container__packages__age__package__slider__slide__card__features__point {
  font-family: "main";
  font-size: 18px;
  font-weight: 500;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 10px;
}
.main__section__rates__container__packages__age__package__slider__slide__card__price {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "main";
  font-size: 36px;
  font-weight: 600;
  font-size: 30px;
  margin-bottom: 20px;
}
.main__section__rates__container__packages__age__package__slider__slide__card__price__note {
  font-family: "main";
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  font-weight: 300;
  font-size: 12px;
}
.main__section__rates__container__packages__age__package__slider__slide__card__button {
  cursor: pointer;
  box-shadow: none;
  min-height: 46px;
  width: fit-content;
  padding: 10px 30px;
  color: #272E48;
  background-color: #E0E7F8;
  border-radius: 30px;
  border: none;
  font-family: "main";
  font-size: 16px;
  text-transform: uppercase;
  transition: all 0.5s ease-out;
  background-color: #272E48;
  color: #E0E7F8;
}
.main__section__rates__container__packages__age__package__slider__slide__card__button:hover {
  box-shadow: inset 9px 9px 10px 0px rgba(0, 0, 0, 0.61);
  transition: all 0.5s ease-out;
}

@font-face {
  font-family: "main";
  src: url(../fonts/Montserrat.ttf);
}
@font-face {
  font-family: "display";
  src: url(../fonts/Gropled-Bold.otf);
}
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #E0E7F8;
}

::-webkit-scrollbar-thumb {
  background-color: #272E48;
  border-radius: 10px;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
    transform: translateY(0);
  }
  10% {
    transform: translateY(-40px);
    transform: translateY(-40px);
  }
  20% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  40% {
    transform: translateY(-30px);
    transform: translateY(-30px);
  }
  50% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  70% {
    transform: translateY(-10px);
    transform: translateY(-10px);
  }
  80% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
}
.main__section__feedback {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #272E48;
  height: fit-content;
  width: 100%;
}
.main__section__feedback__waves {
  object-fit: cover;
  width: 100%;
}
.main__section__feedback__container {
  max-height: 50vh;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  min-width: calc(100vw - 80px);
  max-width: calc(100vw - 80px);
}
.main__section__feedback__container__title {
  font-family: "display";
  line-height: 125%;
  font-size: 60px;
  font-weight: 700;
  color: #E0E7F8;
}
@media (min-width: 0px) and (max-width: 650px) {
  .main__section__feedback__container__title {
    font-size: 48px;
  }
}
.main__section__feedback__container__content {
  min-width: fit-content;
  max-width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main__section__feedback__container__content__slider {
  min-width: 80vw;
  max-width: 80vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main__section__feedback__container__content__slider .swiper-button-prev {
  content: url("../img/arrow_left_light.svg");
}
.main__section__feedback__container__content__slider .swiper-button-next {
  content: url("../img/arrow_right_light.svg");
}
.main__section__feedback__container__content__slider .swiper-pagination .swiper-pagination-bullet {
  background-color: #E0E7F8 !important;
}
.main__section__feedback__container__content__slider .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #FFC93C !important;
}
.main__section__feedback__container__content__slider__slide {
  display: flex !important;
  justify-content: center;
  align-items: center;
  overflow: clip;
  margin-bottom: 20px;
}
.main__section__feedback__container__content__slider__slide__comment {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 30vw;
  height: 20vh;
  border-bottom-right-radius: 50px;
  border-top-left-radius: 50px;
  background-color: #E0E7F8;
  box-shadow: 7px 7px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 20px;
  margin-bottom: 30px;
  margin-top: 25px;
}
.main__section__feedback__container__content__slider__slide__comment__points1 {
  position: absolute;
  top: -20px;
  left: 60px;
}
.main__section__feedback__container__content__slider__slide__comment__points2 {
  position: absolute;
  bottom: -20px;
  right: 30px;
}
.main__section__feedback__container__content__slider__slide__comment__avatar {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
}
.main__section__feedback__container__content__slider__slide__comment__avatar__img {
  align-self: flex-start;
  border-radius: 30px;
  margin: 5px 15px;
  width: 60px;
}
.main__section__feedback__container__content__slider__slide__comment__avatar__state {
  font-family: "main";
  font-size: 12px;
  font-weight: 400;
  color: #575757;
}
.main__section__feedback__container__content__slider__slide__comment__likes {
  font-family: "main";
  font-size: 12px;
  font-weight: 500;
  color: #272E48;
  position: absolute;
  top: 0px;
  right: 0px;
  margin: 10px;
}
.main__section__feedback__container__content__slider__slide__comment__likes__img {
  width: 20px;
}
.main__section__feedback__container__content__slider__slide__comment__info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  margin: 5px 0px 10px 0px;
  font-family: "main";
  font-size: 18px;
  font-weight: 500;
}
.main__section__feedback__container__content__slider__slide__comment__info__name {
  color: #272E48;
  font-weight: 600;
  text-decoration: none;
  transition: all 0.5s ease-out;
}
.main__section__feedback__container__content__slider__slide__comment__info__name:hover {
  text-decoration: underline;
  color: #c48e19;
  transition: all 0.5s ease-out;
}
.main__section__feedback__container__content__slider__slide__comment__info__date {
  margin-bottom: 10px;
  font-size: 13px;
  color: rgba(97, 97, 97, 0.7137254902);
  font-weight: 400;
  text-decoration: none;
}
.main__section__feedback__container__content__slider__slide__comment__info__date:hover {
  text-decoration: underline;
  color: #c48e19;
  transition: all 0.5s ease-out;
}
.main__section__feedback__container__content__slider__slide__comment__info__text {
  font-family: "main";
  font-size: 18px;
  font-weight: 500;
  color: #272E48;
  font-weight: 400;
  font-size: 16px;
  line-height: 175%;
  max-height: 10em;
  overflow-y: auto;
  padding-right: 10px;
}

@font-face {
  font-family: "main";
  src: url(../fonts/Montserrat.ttf);
}
@font-face {
  font-family: "display";
  src: url(../fonts/Gropled-Bold.otf);
}
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #E0E7F8;
}

::-webkit-scrollbar-thumb {
  background-color: #272E48;
  border-radius: 10px;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
    transform: translateY(0);
  }
  10% {
    transform: translateY(-40px);
    transform: translateY(-40px);
  }
  20% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  40% {
    transform: translateY(-30px);
    transform: translateY(-30px);
  }
  50% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  70% {
    transform: translateY(-10px);
    transform: translateY(-10px);
  }
  80% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
}
.main__section__teachers {
  position: relative;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0px;
  padding-top: 50px;
  margin-bottom: 50px;
}
.main__section__teachers__title {
  font-family: "display";
  line-height: 125%;
  font-size: 60px;
  font-weight: 700;
  text-align: center;
  margin: 10px 30px;
  color: #272E48;
}
@media (min-width: 0px) and (max-width: 650px) {
  .main__section__teachers__title {
    font-size: 48px;
  }
}
.main__section__teachers__container {
  min-height: fit-content;
  max-height: fit-content;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-wrap: wrap;
  min-width: calc(100vw - 200px);
  max-width: calc(100vw - 200px);
}
.main__section__teachers__container__slider {
  width: 90vw;
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.main__section__teachers__container__slider.swiper-wrapper {
  align-items: center;
}
.main__section__teachers__container__slider__slide {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}
.main__section__teachers__container__slider__slide__name {
  font-family: "main";
  font-size: 24px;
  font-weight: 500;
  color: #272E48;
  font-size: 18px;
  text-align: center;
  width: 80%;
}
.main__section__teachers__container__slider__slide__card {
  position: relative;
  width: 100%;
  width: 70%;
  margin-bottom: 10px;
}
.main__section__teachers__container__slider__slide__card__img {
  width: 100%;
  border-radius: 30px;
  box-shadow: 7px 7px 4px 0px rgba(0, 0, 0, 0.25);
}
.main__section__teachers__container__slider__slide__card__hover {
  opacity: 0;
  background: linear-gradient(360deg, rgba(0, 0, 0, 0.7) 6.77%, rgba(0, 0, 0, 0) 100%);
  border-radius: 30px;
  position: absolute;
  bottom: 5px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 0px;
  transition: all 0.5s ease-out;
}
.main__section__teachers__container__slider__slide__card__hover:hover {
  opacity: 1;
  transition: all 0.5s ease-out;
}
.main__section__teachers__container__slider__slide__card__hover__container {
  padding: 15px;
  max-height: calc(100% - 30px);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}
.main__section__teachers__container__slider__slide__card__hover__container__name {
  color: #fff;
  margin-bottom: 10px;
  font-family: "main";
  font-size: 16px;
  font-weight: 500;
}
.main__section__teachers__container__slider__slide__card__hover__container__info {
  color: #fff;
  margin-bottom: 5px;
  font-family: "main";
  font-size: 14px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 60%;
}
.main__section__teachers__container__slider__slide__card__hover__container__button {
  cursor: pointer;
  box-shadow: none;
  min-height: 46px;
  width: fit-content;
  padding: 10px 30px;
  color: #272E48;
  background-color: #E0E7F8;
  border-radius: 30px;
  border: none;
  font-family: "main";
  font-size: 16px;
  text-transform: uppercase;
  transition: all 0.5s ease-out;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 15px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  font-family: "main";
  font-size: 14px;
  font-weight: 400;
  text-transform: none;
  align-self: flex-end;
}
.main__section__teachers__container__slider__slide__card__hover__container__button:hover {
  box-shadow: inset 9px 9px 10px 0px rgba(0, 0, 0, 0.61);
  transition: all 0.5s ease-out;
}
.main__section__teachers__container__slider__slide__card__hover__container__button__arrow {
  margin-left: 5px;
}
.main__section__teachers__popUp {
  margin: auto;
  display: flex !important;
  justify-content: center;
  align-items: center;
  position: relative;
}
.main__section__teachers__popUp__modal {
  position: relative;
  width: 60vw;
  height: 80vh;
  margin: 0px;
}
.main__section__teachers__popUp__modal__content {
  max-height: 80vh !important;
  width: 100% !important;
  margin: 0px !important;
  border-radius: 30px;
  z-index: 1;
  display: flex;
  justify-content: space-evenly;
  padding: 45px 45px 30px 30px;
  position: relative;
  background: linear-gradient(90deg, rgba(255, 201, 60, 0.33) 0%, rgba(255, 201, 60, 0) 100%) !important;
}
.main__section__teachers__popUp__modal__content__left {
  width: 30%;
  margin-right: 60px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.main__section__teachers__popUp__modal__content__left__img {
  width: 60%;
  border-radius: 200px;
  margin-bottom: 20px;
}
.main__section__teachers__popUp__modal__content__left__info {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.main__section__teachers__popUp__modal__content__left__info__name {
  font-family: "main";
  font-size: 24px;
  font-weight: 500;
  color: #272E48;
  font-weight: 600;
  margin-bottom: 5px;
  text-align: center;
}
.main__section__teachers__popUp__modal__content__left__info__post {
  font-family: "main";
  font-size: 18px;
  font-weight: 500;
  font-size: 14px;
  font-weight: 400;
  color: #636363;
  text-align: center;
}
.main__section__teachers__popUp__modal__content__right {
  width: 60%;
  max-height: 40rem;
  overflow-y: auto;
  padding-right: 20px;
}
.main__section__teachers__popUp__modal__content__right__text {
  font-family: "main";
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 15px;
  color: #272E48;
  font-weight: 400;
  font-size: 16px;
  line-height: 175%;
  text-indent: 20px;
}

.window__teacher {
  background-color: #E0E7F8 !important;
}

@font-face {
  font-family: "main";
  src: url(../fonts/Montserrat.ttf);
}
@font-face {
  font-family: "display";
  src: url(../fonts/Gropled-Bold.otf);
}
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #E0E7F8;
}

::-webkit-scrollbar-thumb {
  background-color: #272E48;
  border-radius: 10px;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
    transform: translateY(0);
  }
  10% {
    transform: translateY(-40px);
    transform: translateY(-40px);
  }
  20% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  40% {
    transform: translateY(-30px);
    transform: translateY(-30px);
  }
  50% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  70% {
    transform: translateY(-10px);
    transform: translateY(-10px);
  }
  80% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
}
.main__section__diplomas {
  position: relative;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0px;
  padding-top: 50px;
  margin-bottom: 50px;
}
.main__section__diplomas__title {
  font-family: "display";
  line-height: 125%;
  font-size: 60px;
  font-weight: 700;
  text-align: center;
  color: #272E48;
}
@media (min-width: 0px) and (max-width: 650px) {
  .main__section__diplomas__title {
    font-size: 48px;
  }
}
.main__section__diplomas__container {
  min-height: fit-content;
  max-height: fit-content;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-wrap: wrap;
  min-width: calc(100vw - 200px);
  max-width: calc(100vw - 200px);
}
.main__section__diplomas__container__slider {
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.main__section__diplomas__container__slider.swiper-wrapper {
  align-items: center;
}
.main__section__diplomas__container__slider__slide {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin-bottom: 40px;
}
.main__section__diplomas__container__slider__slide__img {
  border: 1px solid #474747;
  position: relative;
  width: 80%;
  max-height: 450px;
  margin-bottom: 10px;
  margin-top: 30px;
  border-radius: 30px;
  box-shadow: 7px 7px 4px 0px rgba(0, 0, 0, 0.25);
}
.main__section__diplomas__popUp {
  margin: 0px auto;
  display: flex !important;
  justify-content: center;
  align-items: center;
  position: relative;
}
.main__section__diplomas__popUp__modal {
  position: relative;
  width: 90vw;
  height: 90vh;
  margin: 0px;
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.main__section__diplomas__popUp__modal__content {
  width: fit-content;
  height: fit-content;
}
.main__section__diplomas__popUp__modal__content__img {
  width: 100%;
  border-radius: 30px;
}

@font-face {
  font-family: "main";
  src: url(../fonts/Montserrat.ttf);
}
@font-face {
  font-family: "display";
  src: url(../fonts/Gropled-Bold.otf);
}
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #E0E7F8;
}

::-webkit-scrollbar-thumb {
  background-color: #272E48;
  border-radius: 10px;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
    transform: translateY(0);
  }
  10% {
    transform: translateY(-40px);
    transform: translateY(-40px);
  }
  20% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  40% {
    transform: translateY(-30px);
    transform: translateY(-30px);
  }
  50% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  70% {
    transform: translateY(-10px);
    transform: translateY(-10px);
  }
  80% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
}
.main {
  min-width: 100%;
  max-width: 100%;
}
.main__section__questions {
  min-height: fit-content;
  max-height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 50px;
}
.main__section__questions__container {
  max-width: 90vw;
  min-width: 90vw;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-wrap: wrap;
  position: relative;
  margin-bottom: 40px;
}
.main__section__questions__container__wrapper {
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.main__section__questions__container__wrapper__question {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 200px;
  height: 200px;
  padding: 30px;
  border-radius: 50%;
  background: radial-gradient(90.77% 92.39% at 31.09% 10.65%, #FFE399 0%, #FFDA7A 24.76%, #FBD266 77.34%, #F5C850 88.15%, #EFC046 100%);
  box-shadow: 3px 7px 10px rgba(0, 0, 0, 0.25);
  transition-duration: 1s;
  margin: 20px;
}
.main__section__questions__container__wrapper__question:hover {
  transition-duration: 1s;
  animation: bounce 1s;
}
.main__section__questions__container__wrapper__question__p {
  font-family: "main";
  font-size: 16px;
  font-weight: 400;
  color: #272E48;
  font-weight: 500;
  text-align: center;
}
.main__section__questions__popUp {
  margin: 0px auto;
  display: flex !important;
  justify-content: center;
  align-items: center;
  position: relative;
}
.main__section__questions__popUp__modal {
  display: flex !important;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 60vw;
  height: 70vh;
  margin: 0px;
}
.main__section__questions__popUp__modal__content {
  width: 100% !important;
  margin: 0px !important;
  border-radius: 30px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 45px 45px 30px 30px;
  position: relative;
  background: linear-gradient(90deg, rgba(255, 201, 60, 0.33) 0%, rgba(255, 201, 60, 0) 100%) !important;
}
.main__section__questions__popUp__modal__content__title {
  font-family: "main";
  font-size: 24px;
  font-weight: 500;
  font-weight: 600;
  max-width: 90vw;
  color: #272E48;
  margin-bottom: 40px;
}
.main__section__questions__popUp__modal__content__answer {
  font-family: "main";
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 15px;
  color: #272E48;
  font-weight: 500;
  font-size: 16px;
  line-height: 175%;
  text-indent: 20px;
  max-height: 50vh;
  overflow-y: auto;
}

.window__teacher {
  background-color: #E0E7F8 !important;
}

@font-face {
  font-family: "main";
  src: url(../fonts/Montserrat.ttf);
}
@font-face {
  font-family: "display";
  src: url(../fonts/Gropled-Bold.otf);
}
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #E0E7F8;
}

::-webkit-scrollbar-thumb {
  background-color: #272E48;
  border-radius: 10px;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
    transform: translateY(0);
  }
  10% {
    transform: translateY(-40px);
    transform: translateY(-40px);
  }
  20% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  40% {
    transform: translateY(-30px);
    transform: translateY(-30px);
  }
  50% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  70% {
    transform: translateY(-10px);
    transform: translateY(-10px);
  }
  80% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
}
.footer {
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 100%;
  position: sticky;
  top: 0px;
  z-index: 20;
  background-color: #272E48;
}
.footer__content {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 40px 30px 40px;
}
.footer__content__left {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  position: relative;
}
.footer__content__left__links {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.footer__content__left__links__link {
  font-family: "main";
  font-size: 16px;
  font-weight: 400;
  color: #E0E7F8;
  margin-right: 15px;
  transition: all 0.5s ease-out;
}
.footer__content__left__links__link:hover {
  text-decoration: underline;
  color: #FFC93C;
  transition: all 0.5s ease-out;
}
.footer__content__left__payments {
  display: flex;
  justify-content: space-evenly;
}
.footer__content__left__payments__payment {
  width: 50px;
  margin-right: 10px;
  margin-bottom: 10px;
}
.footer__content__left__payments__payment:nth-child(5) {
  margin-right: 0px;
}
.footer__content__left__rights {
  font-family: "main";
  font-size: 14px;
  font-weight: 300;
  color: #E0E7F8;
}
.footer__content__right {
  display: flex;
  margin-right: 65px;
}
.footer__content__right__contacts {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}
.footer__content__right__contacts__link {
  margin-bottom: 5px;
  font-family: "main";
  font-size: 16px;
  font-weight: 400;
  color: #E0E7F8;
  margin-right: 15px;
  font-weight: 500;
  transition: all 0.5s ease-out;
}
.footer__content__right__contacts__link:hover {
  text-decoration: underline;
  color: #FFC93C;
  transition: all 0.5s ease-out;
}
.footer__content__right__contacts__link:nth-child(2) {
  margin-bottom: 15px;
}
.footer__content__right__contacts__info {
  font-family: "main";
  font-size: 16px;
  font-weight: 400;
  font-size: 14px;
  color: #E0E7F8;
}
.footer__content__right__networks {
  display: flex;
  flex-direction: column;
}
.footer__content__right__networks__item {
  width: fit-content;
}
.footer__content__right__networks__item__img {
  width: 50px;
}

@font-face {
  font-family: "main";
  src: url(../fonts/Montserrat.ttf);
}
@font-face {
  font-family: "display";
  src: url(../fonts/Gropled-Bold.otf);
}
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #E0E7F8;
}

::-webkit-scrollbar-thumb {
  background-color: #272E48;
  border-radius: 10px;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
    transform: translateY(0);
  }
  10% {
    transform: translateY(-40px);
    transform: translateY(-40px);
  }
  20% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  40% {
    transform: translateY(-30px);
    transform: translateY(-30px);
  }
  50% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  70% {
    transform: translateY(-10px);
    transform: translateY(-10px);
  }
  80% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
}
.main__section {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.main__section__form {
  margin: 0px auto;
  display: flex !important;
  justify-content: center !important;
  align-items: center;
  position: relative;
}
.main__section__form__layout {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: fit-content;
  position: relative;
  max-height: 70vh;
  border-radius: 30px;
  padding: 25px 20px 20px;
  background-color: #E0E7F8 !important;
  height: fit-content;
  max-height: fit-content;
  overflow: auto;
}
.main__section__form__layout__success {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background-color: #E0E7F8;
}
.main__section__form__layout__success__title {
  font-family: "main";
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 10px;
  text-align: center;
}
.main__section__form__layout__success__p {
  font-family: "main";
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 15px;
}
.main__section__form__layout__title {
  font-family: "main";
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 10px;
  text-align: center;
}
.main__section__form__layout__text {
  font-family: "main";
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 15px;
  width: 80%;
}
.main__section__form__layout__item {
  width: fit-content;
  height: fit-content;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.main__section__form__layout__item__label {
  font-family: "main";
  font-size: 18px;
  font-weight: 500;
  font-size: 14px;
  margin-right: 15px;
}
.main__section__form__layout__item__input {
  height: fit-content;
  padding: 10px;
  border-radius: 30px;
  background-color: #E0E7F8;
  border: 1px solid #474747;
}
.main__section__form__layout__item__input:focus {
  border: none;
  border: 2px solid #FFC93C;
  outline: none;
}
.main__section__form__layout__item__error {
  font-family: "main";
  font-size: 18px;
  font-weight: 500;
  font-size: 12px;
  color: #550000;
  width: 100%;
  margin: 0px;
  padding: 0px;
  text-align: center;
}
.main__section__form__layout__button {
  cursor: pointer;
  box-shadow: none;
  min-height: 46px;
  width: fit-content;
  padding: 10px 30px;
  color: #272E48;
  background-color: #E0E7F8;
  border-radius: 30px;
  border: none;
  font-family: "main";
  font-size: 16px;
  text-transform: uppercase;
  transition: all 0.5s ease-out;
  background-color: #272E48;
  color: #E0E7F8;
}
.main__section__form__layout__button:hover {
  box-shadow: inset 9px 9px 10px 0px rgba(0, 0, 0, 0.61);
  transition: all 0.5s ease-out;
}

.main__section__form__layout__button.disabled {
  background-color: #747474;
  cursor: not-allowed;
}
.main__section__form__layout__button.disabled:hover {
  box-shadow: none;
  cursor: not-allowed;
}

.payment-iphone-class {
  justify-content: flex-start;
  width: 100%;
  position: relative;
  padding: 100px 0px 0px 0px;
  min-height: calc(100vh - 90px);
  height: calc(100vh - 90px);
  max-height: calc(100vh - 90px);
  border-radius: 0px;
  overflow: hidden;
}
.payment-iphone-class__backButton {
  position: absolute;
  top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  border-radius: 15px;
  background-color: transparent;
  border: none;
  color: #272E48;
  z-index: 1;
}
.payment-iphone-class__backButton:hover {
  text-decoration: underline;
}
.payment-iphone-class__backButton__arrow {
  height: 20px;
  margin-right: 5px;
}
.payment-iphone-class__waves {
  position: absolute;
  bottom: -10px;
  z-index: 0;
}

@font-face {
  font-family: "main";
  src: url(../fonts/Montserrat.ttf);
}
@font-face {
  font-family: "display";
  src: url(../fonts/Gropled-Bold.otf);
}
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #E0E7F8;
}

::-webkit-scrollbar-thumb {
  background-color: #272E48;
  border-radius: 10px;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
    transform: translateY(0);
  }
  10% {
    transform: translateY(-40px);
    transform: translateY(-40px);
  }
  20% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  40% {
    transform: translateY(-30px);
    transform: translateY(-30px);
  }
  50% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  70% {
    transform: translateY(-10px);
    transform: translateY(-10px);
  }
  80% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
}
.main__docs__modal__layout {
  border-radius: 30px;
  width: 90vw !important;
  height: 90vh !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main__docs__modal__layout__content {
  position: relative;
  width: fit-content;
  display: flex;
  flex-direction: column;
  padding: 0px 20px 0px 50px;
  margin: 0px 22px 0px 0px;
  max-height: 80vh !important;
  overflow-y: auto;
  overflow-x: clip;
}
@media (max-width: 768px) {
  .main__docs__modal__layout__content {
    padding: 0px 10px 0px 35px;
  }
}
.main__docs__modal__layout__content__title {
  font-family: "main";
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px;
  color: #272E48;
}
@media (max-width: 670px) {
  .main__docs__modal__layout__content__title {
    font-size: 20px;
  }
}
@media (max-width: 550px) {
  .main__docs__modal__layout__content__title {
    font-size: 18px;
  }
}
.main__docs__modal__layout__content__a {
  font-family: "main";
  font-size: 16px;
  font-weight: 400;
  color: #272E48;
  text-decoration: none;
  text-align: center;
  margin-bottom: 10px;
  transition: all 0.5s ease-out;
}
.main__docs__modal__layout__content__a:hover {
  text-decoration: underline;
  color: #c18f0d;
  transition: all 0.5s ease-out;
}
.main__docs__modal__layout__content__p {
  font-family: "main";
  font-size: 16px;
  font-weight: 400;
  color: #272E48;
  text-decoration: none;
  text-align: start;
  padding: 0px 50px;
  margin-bottom: 10px;
  line-height: 200%;
  transition: all 0.5s ease-out;
}
@media (max-width: 768px) {
  .main__docs__modal__layout__content__p {
    font-size: 16px;
  }
}
.main__docs__modal__layout__content__ol__li {
  font-family: "main";
  font-size: 18px;
  font-weight: 500;
  color: #272E48;
  text-decoration: none;
  text-align: start;
  margin-bottom: 10px;
  line-height: 200%;
  transition: all 0.5s ease-out;
}
@media (max-width: 768px) {
  .main__docs__modal__layout__content__ol__li {
    font-size: 16px;
  }
}
@media (max-width: 425px) {
  .main__docs__modal__layout__content__ol__li {
    font-size: 14px;
  }
}
.main__docs__modal__layout__content__ol__li__ul__li {
  font-family: "main";
  font-size: 16px;
  font-weight: 400;
  color: #272E48;
  text-decoration: none;
  text-align: start;
  margin-bottom: 10px;
  line-height: 200%;
  transition: all 0.5s ease-out;
}
@media (max-width: 768px) {
  .main__docs__modal__layout__content__ol__li__ul__li {
    font-size: 14px;
  }
}
@media (max-width: 425px) {
  .main__docs__modal__layout__content__ol__li__ul__li {
    font-size: 12px;
  }
}
.main__docs__modal__layout__content__ol__li__ul__ways {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.main__docs__modal__layout__content__ol__li__ul__ways__img {
  margin-right: 20px;
  width: 150px;
}
@media (max-width: 768px) {
  .main__docs__modal__layout__content__ol__li__ul__ways__img {
    width: 70px;
  }
}
@media (max-width: 425px) {
  .main__docs__modal__layout__content__ol__li__ul__ways__img {
    width: 40px;
  }
}
.main__docs__modal__layout__content__first {
  font-family: "main";
  font-size: 18px;
  font-weight: 500;
  color: #272E48;
  text-decoration: none;
  text-align: start;
  margin-bottom: 10px;
  line-height: 200%;
  transition: all 0.5s ease-out;
}
@media (max-width: 768px) {
  .main__docs__modal__layout__content__first {
    font-size: 16px;
  }
}
@media (max-width: 425px) {
  .main__docs__modal__layout__content__first {
    font-size: 14px;
  }
}
.main__docs__modal__layout__content__second, .main__docs__modal__layout__content__third {
  font-family: "main";
  font-size: 16px;
  font-weight: 400;
  color: #272E48;
  text-decoration: none;
  text-align: start;
  margin-bottom: 10px;
  line-height: 200%;
  transition: all 0.5s ease-out;
}
.main__docs__modal__layout__content__second {
  margin-left: 10px;
}
.main__docs__modal__layout__content__third {
  margin-left: 20px;
}
@media (max-width: 768px) {
  .main__docs__modal__layout__content__second, .main__docs__modal__layout__content__third {
    font-size: 14px;
  }
}
@media (max-width: 425px) {
  .main__docs__modal__layout__content__second, .main__docs__modal__layout__content__third {
    font-size: 12px;
  }
}

@font-face {
  font-family: "main";
  src: url(../fonts/Montserrat.ttf);
}
@font-face {
  font-family: "display";
  src: url(../fonts/Gropled-Bold.otf);
}
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #E0E7F8;
}

::-webkit-scrollbar-thumb {
  background-color: #272E48;
  border-radius: 10px;
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
    transform: translateY(0);
  }
  10% {
    transform: translateY(-40px);
    transform: translateY(-40px);
  }
  20% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  40% {
    transform: translateY(-30px);
    transform: translateY(-30px);
  }
  50% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
  70% {
    transform: translateY(-10px);
    transform: translateY(-10px);
  }
  80% {
    transform: translateY(0px);
    transform: translateY(0px);
  }
}
@media (min-width: 0px) and (max-width: 1342px) {
  .header__content__nav__a {
    margin-right: 20px;
  }
}
@media (min-width: 0px) and (max-width: 1342px) and (min-width: 0px) and (max-width: 1082px) {
  .header__content__nav__a {
    margin-right: 15px;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .footer__content__left__links {
    flex-direction: column;
  }
}
@media (min-width: 769px) and (max-width: 1024px) and (min-width: 0px) and (max-width: 1000px) {
  .footer__content__right {
    flex-direction: column;
  }
  .footer__content__right__contacts__info {
    margin-bottom: 10px;
  }
  .footer__content__right__networks {
    flex-direction: row;
  }
  .footer__content__right__networks__item {
    margin-right: 20px;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .footer {
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .footer__content {
    margin: 7px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: fit-content;
  }
  .footer__content__contacts {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .footer__content__contacts__links {
    display: flex;
    flex-direction: column;
  }
  .footer__content__contacts__links__link {
    margin-bottom: 5px;
    font-family: "main";
    font-size: 16px;
    font-weight: 400;
    font-size: 14px;
    color: #E0E7F8;
    margin-right: 15px;
    font-weight: 500;
    transition: all 0.5s ease-out;
  }
  .footer__content__contacts__links__link:hover {
    text-decoration: underline;
    color: #FFC93C;
    transition: all 0.5s ease-out;
  }
  .footer__content__contacts__links__link:nth-child(2) {
    margin-bottom: 15px;
  }
  .footer__content__contacts__networks {
    width: fit-content;
    display: flex;
    justify-content: space-evenly;
    align-self: center;
    flex-direction: column;
  }
  .footer__content__contacts__networks__item__img {
    width: 30px;
  }
  .footer__content__docs {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  .footer__content__docs__link {
    font-family: "main";
    font-size: 16px;
    font-weight: 400;
    font-size: 14px;
    color: #E0E7F8;
    margin-right: 15px;
    transition: all 0.5s ease-out;
  }
  .footer__content__docs__link:hover {
    text-decoration: underline;
    color: #FFC93C;
    transition: all 0.5s ease-out;
  }
  .footer__content__payments {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
  }
  .footer__content__payments__payment {
    width: 30px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .footer__content__payments__payment:nth-child(5) {
    margin-right: 0px;
  }
  .footer__content__info {
    font-family: "main";
    font-size: 16px;
    font-weight: 400;
    font-size: 12px;
    color: #E0E7F8;
    margin-bottom: 10px;
  }
  .footer__content__rights {
    font-family: "main";
    font-size: 10px;
    font-weight: 300;
    color: #E0E7F8;
  }
}
@media (min-width: 0px) and (max-width: 650px) {
  .main__section__advantages {
    margin-bottom: 50px;
  }
  .main__section__advantages__h1 {
    font-family: "display";
    line-height: 125%;
    font-size: 60px;
    font-weight: 700;
    font-size: 48px;
    color: #272E48;
    margin-bottom: 50px;
  }
}
@media (min-width: 0px) and (max-width: 650px) and (min-width: 0px) and (max-width: 650px) {
  .main__section__advantages__h1 {
    font-size: 48px;
  }
}
@media (min-width: 0px) and (max-width: 650px) {
  .main__section__advantages__container {
    min-width: calc(100vw - 30px);
    max-width: calc(100vw - 30px);
  }
}
@media (min-width: 0px) and (max-width: 650px) and (min-width: 0px) and (max-width: 426px) {
  .main__section__advantages__container__slider .swiper-button-prev {
    display: block !important;
    content: url("../img/arrow-left-rates.svg");
    top: auto !important;
    bottom: 0px !important;
    left: 10%;
    z-index: 11;
  }
  .main__section__advantages__container__slider .swiper-button-next {
    display: block !important;
    content: url("../img/arrow-right-rates.svg");
    top: auto !important;
    bottom: 0px !important;
    right: 10%;
    z-index: 11;
  }
}
@media (min-width: 0px) and (max-width: 650px) {
  .main__section__advantages__container__slider__slide__name {
    font-family: "main";
    font-size: 24px;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    min-width: 250px;
    max-width: 250px;
  }
}
@media (min-width: 0px) and (max-width: 950px) {
  .main__section__forWhom__container__h1 {
    margin-bottom: 0px;
  }
  .main__section__forWhom__container__content {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: flex-start;
  }
  .main__section__forWhom__container__content__box {
    min-width: 95vw;
    max-width: 95vw;
    margin-bottom: 30px;
  }
  .main__section__forWhom__container__content__box__title {
    min-width: 70vw;
    max-width: 70vw;
    margin-bottom: 40px;
    font-size: 18px;
  }
  .main__section__forWhom__container__content__box__choice {
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-evenly;
  }
  .main__section__forWhom__container__content__box__choice__age {
    justify-content: space-evenly;
  }
  .main__section__forWhom__container__content__box__choice__age:nth-child(1) {
    margin-right: 0px;
    margin-bottom: 0px;
  }
  .main__section__forWhom__container__content__box__choice__age__img {
    width: 30vw;
    margin-bottom: 0px;
  }
  .main__section__forWhom__container__content__box__choice__age__button {
    cursor: pointer;
    box-shadow: none;
    min-height: 46px;
    width: fit-content;
    padding: 10px 30px;
    color: #272E48;
    background-color: #E0E7F8;
    border-radius: 30px;
    border: none;
    font-family: "main";
    font-size: 16px;
    text-transform: uppercase;
    transition: all 0.5s ease-out;
    font-size: 13px;
    padding: 5px 10px;
    min-height: 35px;
    margin-top: 10px;
    text-transform: none;
    color: #E0E7F8;
    background-color: #272E48;
  }
  .main__section__forWhom__container__content__box__choice__age__button:hover {
    box-shadow: inset 9px 9px 10px 0px rgba(0, 0, 0, 0.61);
    transition: all 0.5s ease-out;
  }
  .main__section__forWhom__container__content__info {
    min-width: 80vw;
    max-width: 80vw;
    max-height: 50vh;
    margin-right: 0px;
  }
}
@media (min-width: 0px) and (max-width: 900px) and (min-width: 601px) and (max-width: 930px) {
  .main__section__about__container {
    padding-bottom: 150px;
  }
}
@media (min-width: 0px) and (max-width: 900px) and (min-width: 0px) and (max-width: 600px) {
  .main__section__about__container {
    padding-bottom: 100px;
  }
}
@media (min-width: 0px) and (max-width: 900px) {
  .main__section__about__container__group1 {
    width: 90vw;
    align-items: center;
  }
}
@media (min-width: 0px) and (max-width: 900px) and (min-width: 0px) and (max-width: 400px) {
  .main__section__about__container__group1 {
    margin-bottom: 0px;
  }
}
@media (min-width: 0px) and (max-width: 900px) {
  .main__section__about__container__group1__left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .main__section__about__container__group1__left__title {
    margin-bottom: 20px;
  }
}
@media (min-width: 0px) and (max-width: 900px) and (min-width: 0px) and (max-width: 400px) {
  .main__section__about__container__group1__left__title {
    padding: 25px 70px;
  }
  .main__section__about__container__group1__left__title__h1 {
    font-size: 36px;
  }
}
@media (min-width: 0px) and (max-width: 900px) {
  .main__section__about__container__group1__left__points {
    margin-left: 0px;
  }
}
@media (min-width: 0px) and (max-width: 900px) and (min-width: 0px) and (max-width: 550px) {
  .main__section__about__container__group1__left__points__point {
    display: flex;
    align-items: flex-start;
  }
  .main__section__about__container__group1__left__points__point__img {
    width: 2.5rem;
    margin-right: 20px;
  }
}
@media (min-width: 0px) and (max-width: 900px) {
  .main__section__about__container__group1__right {
    display: none;
  }
  .main__section__about__container__group2 {
    width: 90vw;
    align-self: center;
  }
  .main__section__about__container__group2__right {
    width: 90vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .main__section__about__container__group2__right__title {
    padding: 40px 70px 15px;
    margin-bottom: 20px;
  }
}
@media (min-width: 0px) and (max-width: 900px) and (min-width: 0px) and (max-width: 400px) {
  .main__section__about__container__group2__right__title {
    padding: 40px 45px 15px;
  }
  .main__section__about__container__group2__right__title__h1 {
    font-size: 18px;
  }
}
@media (min-width: 0px) and (max-width: 900px) {
  .main__section__about__container__group2__right__title__h1 {
    font-size: 36px;
  }
  .main__section__about__container__group2__right__points {
    margin-left: 0px;
    align-self: flex-start;
  }
}
@media (min-width: 0px) and (max-width: 900px) and (min-width: 0px) and (max-width: 550px) {
  .main__section__about__container__group2__right__points__point {
    margin-bottom: 60px;
    display: flex;
    align-items: flex-start;
  }
  .main__section__about__container__group2__right__points__point__img {
    width: 2.5rem;
    margin-right: 20px;
  }
}
@media (min-width: 0px) and (max-width: 900px) {
  .main__section__about__container__group2__left {
    display: none;
  }
  .main__section__about__container__button {
    margin: 0px;
  }
}
@media (min-width: 0px) and (max-width: 768px) {
  .main__section__results__container__title {
    font-size: 18px;
  }
  .main__section__results__container__button {
    margin-right: 0px;
  }
  .main__section__results__container__content__points {
    margin-left: 0px;
    width: 90vw;
  }
  .main__section__results__container__content__points__point {
    display: flex;
    align-items: flex-start;
    margin-bottom: 15px;
  }
  .main__section__results__container__content__points__point__img {
    margin-right: 10px;
  }
  .main__section__results__container__content__points__point__text {
    font-size: 16px;
    font-weight: 400;
    line-height: 175%;
  }
  .main__section__results__container__content__img {
    display: none;
  }
}
@media (min-width: 0px) and (max-width: 1100px) and (min-width: 0px) and (max-width: 770px) {
  .main__section__rates__container__choice {
    width: 90vw !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .main__section__rates__container__choice__age {
    font-size: 13px !important;
    margin-bottom: 10px !important;
  }
  .main__section__rates__container__choice__age__span {
    font-size: 10px !important;
  }
}
@media (min-width: 0px) and (max-width: 1100px) {
  .main__section__rates__container__choice__age:nth-child(1) {
    margin-right: 0px !important;
  }
  .main__section__rates__container__choice__age:nth-child(2) {
    margin-right: 0px !important;
  }
  .main__section__rates__container__packages__age {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .main__section__rates__container__packages__age__package .groupTitle {
    margin-top: 25px;
    margin-bottom: 15px;
  }
  .main__section__rates__container__packages__age__package__slider {
    margin-bottom: 0px;
    width: 98vw !important;
  }
  .main__section__rates__container__packages__age__package__slider__slide__card {
    min-height: 570px !important;
    max-height: 570px !important;
  }
}
@media (min-width: 0px) and (max-width: 1100px) and (min-width: 0px) and (max-width: 376px) {
  .main__section__rates__container__packages__age__package__slider__slide__card {
    width: 90vw !important;
    padding: 10px 3px !important;
  }
}
@media (min-width: 0px) and (max-width: 1100px) and (min-width: 0px) and (max-width: 376px) {
  .main__section__rates__container__packages__age__package__slider__slide__card__title {
    font-size: 24px !important;
  }
}
@media (min-width: 0px) and (max-width: 1100px) and (min-width: 0px) and (max-width: 376px) {
  .main__section__rates__container__packages__age__package__slider__slide__card__features {
    width: 90% !important;
  }
}
@media (min-width: 0px) and (max-width: 1100px) {
  .main__section__rates__container__packages__age__package__slider__slide__card__price {
    font-size: 24px !important;
  }
  .main__section__rates__container__packages__age__package__slider__slide__card__price__note {
    font-size: 10px !important;
  }
}
@media (min-width: 0px) and (max-width: 1325px) and (min-width: 0px) and (max-width: 768px) {
  .main__section__feedback__container__content__slider {
    min-width: 98vw;
    max-width: 98vw;
  }
}
@media (min-width: 0px) and (max-width: 1325px) and (min-width: 0px) and (max-width: 650px) {
  .main__section__feedback__container__content__slider .swiper-button-prev {
    display: block !important;
    top: auto !important;
    bottom: 0px !important;
    left: 10%;
    z-index: 11;
  }
  .main__section__feedback__container__content__slider .swiper-button-next {
    display: block !important;
    top: auto !important;
    bottom: 0px !important;
    right: 10%;
    z-index: 11;
  }
}
@media (min-width: 0px) and (max-width: 1325px) {
  .main__section__feedback__container__content__slider__slide {
    margin-bottom: 20px;
  }
  .main__section__feedback__container__content__slider__slide__comment {
    width: 45vw;
  }
}
@media (min-width: 0px) and (max-width: 1325px) and (min-width: 0px) and (max-width: 1024px) {
  .main__section__feedback__container__content__slider__slide__comment {
    width: 60vw;
  }
}
@media (min-width: 0px) and (max-width: 1325px) and (min-width: 0px) and (max-width: 768px) {
  .main__section__feedback__container__content__slider__slide__comment {
    width: 70vw;
  }
}
@media (min-width: 0px) and (max-width: 500px) {
  .main__section__feedback__container {
    max-height: fit-content;
    min-height: fit-content;
  }
  .main__section__feedback__container__title {
    margin-top: 30px;
  }
  .main__section__feedback__container__content__slider .swiper-pagination {
    width: 60vw !important;
    left: 19% !important;
  }
  .main__section__feedback__container__content__slider__slide {
    margin-bottom: 60px;
  }
  .main__section__feedback__container__content__slider__slide__comment {
    height: 30vh;
    width: 80vw;
    flex-direction: column;
  }
  .main__section__feedback__container__content__slider__slide__comment__person {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .main__section__feedback__container__content__slider__slide__comment__person__avatar {
    margin-right: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .main__section__feedback__container__content__slider__slide__comment__person__avatar__img {
    border-radius: 30px;
    margin: 5px 15px;
    width: 60px;
  }
  .main__section__feedback__container__content__slider__slide__comment__person__avatar__state {
    font-family: "main";
    font-size: 12px;
    font-weight: 400;
    color: #575757;
  }
  .main__section__feedback__container__content__slider__slide__comment__person__links {
    display: flex;
    flex-direction: column;
  }
  .main__section__feedback__container__content__slider__slide__comment__person__links__name {
    color: #272E48;
    font-weight: 600;
    text-decoration: none;
    transition: all 0.5s ease-out;
  }
  .main__section__feedback__container__content__slider__slide__comment__person__links__name:hover {
    text-decoration: underline;
    color: #c48e19;
    transition: all 0.5s ease-out;
  }
  .main__section__feedback__container__content__slider__slide__comment__person__links__date {
    margin-bottom: 10px;
    font-size: 13px;
    color: rgba(97, 97, 97, 0.7137254902);
    font-weight: 400;
    text-decoration: none;
  }
  .main__section__feedback__container__content__slider__slide__comment__person__links__date:hover {
    text-decoration: underline;
    color: #c48e19;
    transition: all 0.5s ease-out;
  }
  .main__section__feedback__container__content__slider__slide__comment__info__text {
    max-height: 11rem;
    overflow-y: auto;
    font-size: 14px;
  }
  .main__section__feedback__container__content__slider__slide__comment__likes {
    top: 0px;
    right: 0px;
  }
}
@media (min-width: 0px) and (max-width: 850px) and (min-width: 0px) and (max-width: 500px) {
  .main__section__teachers__title {
    font-size: 36px;
  }
}
@media (min-width: 0px) and (max-width: 850px) {
  .main__section__teachers__container {
    min-width: 90vw;
    max-width: 90vw;
  }
}
@media (min-width: 0px) and (max-width: 850px) and (min-width: 0px) and (max-width: 550px) {
  .main__section__teachers__container__slider .swiper-button-prev {
    display: block !important;
    top: auto !important;
    bottom: 0px !important;
    left: 10%;
    z-index: 11;
  }
  .main__section__teachers__container__slider .swiper-button-next {
    display: block !important;
    top: auto !important;
    bottom: 0px !important;
    right: 10%;
    z-index: 11;
  }
}
@media (min-width: 0px) and (max-width: 850px) and (min-width: 0px) and (max-width: 550px) {
  .main__section__teachers__container__slider__slide__card {
    width: 90%;
  }
}
@media (min-width: 0px) and (max-width: 850px) {
  .main__section__teachers__popUp__modal__content__right__text {
    font-size: 14px;
  }
}
@media (min-width: 0px) and (max-width: 1240px) and (min-width: 0px) and (max-width: 900px) {
  .main__section__teachers__popUp__modal {
    width: 90vw;
  }
}
@media (min-width: 0px) and (max-width: 1240px) {
  .main__section__teachers__popUp__modal__content {
    flex-direction: column;
  }
}
@media (min-width: 0px) and (max-width: 1240px) and (min-width: 0px) and (max-width: 570px) {
  .main__section__teachers__popUp__modal__content {
    padding: 25px 25px 15px 15px;
  }
}
@media (min-width: 0px) and (max-width: 1240px) {
  .main__section__teachers__popUp__modal__content__left {
    margin-right: 0px;
    margin-bottom: 10px;
    width: 100%;
    flex-direction: row;
    align-items: flex-start;
  }
  .main__section__teachers__popUp__modal__content__left__img {
    width: 20%;
    margin-right: 30px;
  }
}
@media (min-width: 0px) and (max-width: 1240px) and (min-width: 0px) and (max-width: 950px) {
  .main__section__teachers__popUp__modal__content__left__img {
    width: 40%;
  }
}
@media (min-width: 0px) and (max-width: 1240px) and (min-width: 0px) and (max-width: 900px) {
  .main__section__teachers__popUp__modal__content__left__img {
    width: 30%;
  }
}
@media (min-width: 0px) and (max-width: 1240px) {
  .main__section__teachers__popUp__modal__content__left__info {
    width: 50%;
  }
}
@media (min-width: 0px) and (max-width: 1240px) and (min-width: 0px) and (max-width: 570px) {
  .main__section__teachers__popUp__modal__content__left__info__name {
    font-size: 4vw;
  }
  .main__section__teachers__popUp__modal__content__left__info__post {
    font-size: 3vw;
  }
}
@media (min-width: 0px) and (max-width: 1240px) {
  .main__section__teachers__popUp__modal__content__left__info__name {
    text-align: start;
  }
  .main__section__teachers__popUp__modal__content__left__info__post {
    text-align: start;
  }
  .main__section__teachers__popUp__modal__content__right {
    width: 100%;
    max-height: 20rem;
  }
}
@media (min-width: 0px) and (max-width: 1240px) and (min-width: 0px) and (max-width: 570px) {
  .main__section__teachers__popUp__modal__content__right {
    max-height: 30rem;
  }
}
@media (min-width: 0px) and (max-width: 850px) and (min-width: 0px) and (max-width: 500px) {
  .main__section__diplomas__title {
    font-size: 36px;
  }
}
@media (min-width: 0px) and (max-width: 850px) {
  .main__section__diplomas__container {
    min-width: 90vw;
    max-width: 90vw;
  }
  .main__section__diplomas__container__slider .swiper-pagination {
    width: 70% !important;
    bottom: 0px !important;
    left: auto !important;
  }
}
@media (min-width: 0px) and (max-width: 850px) and (min-width: 0px) and (max-width: 550px) {
  .main__section__diplomas__container__slider .swiper-button-prev {
    display: block !important;
    top: auto !important;
    bottom: 0px !important;
    left: 10%;
    z-index: 11;
  }
  .main__section__diplomas__container__slider .swiper-button-next {
    display: block !important;
    top: auto !important;
    bottom: 0px !important;
    right: 10%;
    z-index: 11;
  }
}
@media (min-width: 0px) and (max-width: 850px) {
  .main__section__diplomas__container__slider__slide {
    margin-bottom: 100px;
  }
  .main__section__diplomas__container__slider__slide__img {
    max-height: 350px;
  }
}
@media (min-width: 0px) and (max-width: 1240px) and (min-width: 0px) and (max-width: 900px) {
  .main__section__questions__popUp__modal {
    width: 90vw;
  }
}
@media (min-width: 0px) and (max-width: 1240px) {
  .main__section__questions__popUp__modal__content {
    flex-direction: column;
  }
}
@media (min-width: 0px) and (max-width: 1240px) and (min-width: 0px) and (max-width: 570px) {
  .main__section__questions__popUp__modal__content {
    padding: 25px 25px 15px 15px;
  }
  .main__section__questions__popUp__modal__content__title {
    margin-bottom: 20px;
  }
  .main__section__questions__popUp__modal__content__answer {
    max-height: 30rem;
  }
}
@media (min-width: 0px) and (max-width: 1240px) and (min-width: 0px) and (max-width: 500px) {
  .main__section__questions__popUp__modal__content {
    padding: 30px 25px 15px 15px;
  }
  .main__section__questions__popUp__modal__content__title {
    font-size: 18px;
    margin-bottom: 20px;
  }
  .main__section__questions__popUp__modal__content__answer {
    max-height: 30rem;
    font-size: 16px;
  }
}
* {
  margin: 0;
  padding: 0;
}
*::selection {
  color: #ffe5a2;
  background-color: #364169;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
@media (min-height: 0px) and (max-height: 375px) {
  .modal {
    top: 0px;
    bottom: unset;
  }
}
.modal-content {
  position: relative;
  padding: 20px;
  border-radius: 8px;
  max-height: 99vh;
  overflow: auto;
}
.modal-close {
  cursor: pointer;
  position: absolute;
  right: 30px;
  top: 30px;
  border: none;
  background-color: transparent;
  padding: 5px 10px;
}

