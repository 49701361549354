@import 'header'
@import 'main'
@import 'advantages'
@import 'forWhom'
@import 'about'
@import 'results'
@import 'rates'
@import 'feedback'
@import 'teachers'
@import 'diplomas'
@import 'questions'
@import 'footer'
@import 'form'
@import 'docs'
@import 'mobile'
*
    margin: 0
    padding: 0
    // max-width: 100%
    // min-width: 100%
    // overflow: hidden
    &::selection
        color: #ffe5a2
        background-color: #364169

// @media screen and ( max-width: 1024 )
//     transform-origin: top left /* Устанавливаем точку масштабирования */
//     transform: scale(0.5) /* Устанавливаем желаемый масштаб (в данном случае 50%) */
//     width: 200% /* Увеличиваем ширину контейнера, чтобы контент не обрезался */
@mixin scale
    transform-origin: top left /* Устанавливаем точку масштабирования */
    transform: scale(0.5) /* Устанавливаем желаемый масштаб (в данном случае 50%) */
    width: 200% /* Увеличиваем ширину контейнера, чтобы контент не обрезался */

.modal
    position: fixed
    top: 0
    left: 0
    right: 0
    bottom: 0
    background-color: rgba(0, 0, 0, 0.5)
    display: flex
    align-items: center
    justify-content: center
    z-index: 1000
    @media ( min-height: 0px ) and ( max-height: 375px )
        top: 0px
        bottom: unset
    &-content
        position: relative
        padding: 20px
        border-radius: 8px
        max-height: 99vh
        overflow: auto
    &-close
        cursor: pointer
        position: absolute
        right: 30px
        top: 30px
        border: none
        background-color: transparent
        padding: 5px 10px
