@import 'variables'

.main
    min-width: 100%
    max-width: 100%
    &__section
        &__questions
            min-height: fit-content
            max-height: fit-content
            display: flex
            flex-direction: column
            justify-content: flex-start
            align-items: center
            padding-top: 50px
            &__container
                max-width: 90vw
                min-width: 90vw
                display: flex
                justify-content: space-evenly
                align-items: flex-start
                flex-wrap: wrap
                position: relative
                margin-bottom: 40px
                &__wrapper
                    width: 300px
                    display: flex
                    justify-content: center
                    align-items: center
                    flex-wrap: wrap
                    &__question
                        cursor: pointer
                        display: flex
                        justify-content: center
                        align-items: center
                        flex-wrap: wrap
                        width: 200px
                        height: 200px
                        padding: 30px
                        border-radius: 50%
                        // background: $third-color
                        background: radial-gradient(90.77% 92.39% at 31.09% 10.65%, #FFE399 0%, #FFDA7A 24.76%, #FBD266 77.34%, #F5C850 88.15%, #EFC046 100%)
                        box-shadow: 3px 7px 10px rgba(0, 0, 0, 0.25)
                        transition-duration: 1s
                        margin: 20px
                        &:hover
                            transition-duration: 1s
                            // transform: rotate(360deg)
                            animation: bounce 1s
                        &__p
                            @include link
                            color: $secondary-color
                            font-weight: 500
                            text-align: center
            &__popUp
                margin: 0px auto
                display: flex !important
                justify-content: center
                align-items: center
                position: relative
                &__modal
                    display: flex !important
                    justify-content: center
                    align-items: center
                    position: relative
                    width: 60vw
                    height: 70vh
                    margin: 0px
                    &__content
                        width: 100% !important
                        margin: 0px !important
                        border-radius: 30px
                        z-index: 1
                        display: flex
                        flex-direction: column
                        justify-content: space-evenly
                        padding: 45px 45px 30px 30px
                        position: relative
                        background: linear-gradient(90deg, rgba(255, 201, 60, 0.33) 0%, rgba(255, 201, 60, 0.00) 100%) !important
                        &__title
                            @include header3
                            font-weight: 600
                            max-width: 90vw
                            color: $secondary-color
                            margin-bottom: 40px
                        &__answer
                            @include text
                            margin-bottom: 15px
                            color: $secondary-color
                            font-weight: 500
                            font-size: 16px
                            line-height: 175%
                            text-indent: 20px
                            max-height: 50vh
                            overflow-y: auto



.window__teacher
    background-color: $main-color !important