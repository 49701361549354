@import 'variables'

.main
    &__section
        &__results
            min-height: fit-content
            max-height: fit-content
            width: 100%
            position: relative
            @include content
            flex-direction: column
            margin: 0px
            background-color: $main-color
            &__container
                width: 90vw
                margin: 50px 0px
                display: flex
                flex-direction: column
                align-items: center
                &__title
                    @include header3
                    font-weight: 600
                    max-width: 90vw
                    color: $secondary-color
                    margin-bottom: 40px
                    align-self: flex-start
                &__button
                    @include buttonContainer
                    max-width: 250px
                    &__hover
                        @include button
                        color: $main-color
                        background-color: $secondary-color
                &__content
                    margin-bottom: 30px
                    display: flex
                    justify-content: space-evenly
                    align-items: flex-start
                    &__points
                        width: 50vw
                        display: flex
                        flex-direction: column
                        align-items: flex-start
                        justify-content: flex-start
                        margin-left: 40px
                        &__point
                            display: flex
                            align-items: center
                            margin-bottom: 30px
                            &__img
                                width: 45px
                                margin-right: 30px
                            &__text
                                @include text
                    &__img
                        align-self: flex-end
                        width: 30vw