@import 'variables'
.header
    height: 80px
    display: flex
    align-items: center
    justify-content: space-between
    min-width: 100%
    position: sticky
    top: 0px
    z-index: 20
    background-color: $secondary-color
    box-shadow: 0px 7px 10px 0px rgba(0, 0, 0, 0.70)
    &__logo
        padding: 0px 50px
        max-height: 80px
        justify-self: flex-start
        cursor: pointer
        @media (max-width: 700px)
            padding: 0px 10px
    &__content
        display: flex
        align-items: center
        margin-right: 50px
        height: 80px
        &__burger
            display: none // Скрываем кнопку "меню" по умолчанию
            @media (max-width: 1024px)
                display: block
                cursor: pointer
        &__nav
            display: flex
            justify-content: flex-end
            align-items: flex-start
            align-self: flex-start
            @media (max-width: 1024px)
                display: none // Скрываем меню на маленьких экранах по умолчанию
            &__a
                text-decoration: none
                text-transform: uppercase
                @include link
                font-size: clamp(9px, calc(0px + 0.9vw), 16px)
                color: $main-color
                padding-top: 31px
                margin-right: 40px
                cursor: pointer
                &:hover
                    border-top: 5px solid $main-color
                    text-transform: uppercase
                    font-family: 'main'
                    font-weight: 400
                    color: $main-color
                    padding-top: 26px
                    text-decoration: none
                    height: 54px
        &__button
            @include buttonContainer
            &__hover
                @include button
                @media (max-width: 700px)
                    padding: 5px 10px
                    font-size: 14px
                @media (max-width: 425px)
                    font-size: 12px
                    min-height: 35px

.header__content__nav__dropdown
    position: relative
    display: none

.active
    .header__content__nav__dropdown-content
        display: flex
        flex-direction: column
        padding: 0px
        position: absolute
        top: 100%
        right: 50px
        background-color: $secondary-color
        padding: 10px
        z-index: 1
        border-bottom-left-radius: 15px
        border-bottom-right-radius: 15px
        box-shadow: 0px 7px 10px 0px rgba(0, 0, 0, 0.70)
        @media ( min-width: 1024px )
            display: none
        &__a
            padding: 10px 20px
            text-decoration: none
            @include link
            color: $main-color
            &:hover
                color: $third-color

.header__content__nav__dropdown-content
    display: none
    // position: absolute
    // top: 100%
    // right: 50px
    // background-color: $secondary-color
    // padding: 10px
    // z-index: 1
    // border-radius: 15px

// .header
//     height: 80px
//     display: flex
//     align-items: center
//     justify-content: space-between
//     min-width: 100%
//     position: sticky
//     top: 0px
//     z-index: 20
//     background-color: $secondary-color
//     box-shadow: (0px 7px 10px 0px rgba(0, 0, 0, 0.70))
//     &__logo
//         padding: 0px 50px
//         max-height: 80px
//         justify-self: flex-start
//     &__content
//         display: flex
//         align-items: center
//         margin-right: 50px
//         height: 80px
//         &__nav
//             display: flex
//             justify-content: flex-end
//             align-items: flex-start
//             align-self: flex-start
//             &__a
//                 text-decoration: none
//                 text-transform: uppercase
//                 font-family: 'main'
//                 font-weight: 400
//                 font-size: 16px
//                 color: $main-color
//                 padding-top: 31px
//                 margin-right: 40px
//                 &:hover
//                     border-top: 5px solid $main-color
//                     text-transform: uppercase
//                     font-family: 'main'
//                     font-weight: 400
//                     color: $main-color
//                     padding-top: 26px
//                     text-decoration: none
//                     height: 54px
//         &__button
//             @include buttonContainer
//             &__hover
//                 @include button
