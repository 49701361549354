@import 'variables'

.main
    &__section
        &__feedback
            position: relative
            display: flex
            flex-direction: column
            align-items: center
            justify-content: center
            // min-width: calc( 100vw - 80px )  
            // min-width: calc( 100vw - 80px )
            background-color: $secondary-color
            height: fit-content
            width: 100%
            &__waves
                // position: absolute
                // bottom: -2px
                object-fit: cover
                width: 100%
            &__container
                // background-color: $secondary-color
                max-height: 50vh
                min-height: 50vh
                display: flex
                flex-direction: column
                justify-content: space-evenly
                align-items: center
                min-width: calc( 100vw - 80px )  
                max-width: calc( 100vw - 80px )
                &__title
                    @include header1
                    // color: $secondary-color
                    color: $main-color
                &__content
                    min-width: fit-content  
                    max-width: fit-content
                    display: flex
                    justify-content: center
                    align-items: center
                    &__slider
                        min-width: 80vw 
                        max-width: 80vw
                        display: flex
                        justify-content: center
                        align-items: center
                        .swiper-button-prev
                            content: url('../img/arrow_left_light.svg')
                        .swiper-button-next
                            content: url('../img/arrow_right_light.svg')
                        .swiper-pagination 
                            .swiper-pagination-bullet
                                background-color: $main-color !important
                            .swiper-pagination-bullet-active
                                background-color: $third-color !important
                        &__slide
                            display: flex !important
                            justify-content: center
                            align-items: center
                            overflow: clip
                            margin-bottom: 20px
                            &__comment
                                position: relative
                                display: flex
                                justify-content: flex-start
                                align-items: flex-start
                                width: 30vw
                                height: 20vh
                                // border: 3px solid #35aeff
                                // border-radius: 30px
                                border-bottom-right-radius: 50px
                                border-top-left-radius: 50px
                                background-color: $main-color
                                box-shadow: 7px 7px 4px 0px rgba(0, 0, 0, 0.25)
                                padding: 20px
                                margin-bottom: 30px
                                margin-top: 25px
                                &__points1
                                    position: absolute
                                    top: -20px
                                    left: 60px
                                &__points2
                                    position: absolute
                                    bottom: -20px
                                    right: 30px
                                &__avatar
                                    display: flex
                                    flex-direction: column
                                    align-items: center
                                    margin-right: 20px
                                    &__img
                                        align-self: flex-start
                                        border-radius: 30px
                                        margin: 5px 15px
                                        width: 60px
                                    &__state
                                        font-family: 'main'
                                        font-size: 12px
                                        font-weight: 400
                                        color: #575757
                                &__likes
                                    font-family: 'main'
                                    font-size: 12px
                                    font-weight: 500
                                    color: $secondary-color
                                    position: absolute
                                    top: 0px
                                    right: 0px
                                    margin: 10px
                                    &__img
                                        width: 20px
                                &__info
                                    display: flex
                                    flex-direction: column
                                    justify-content: flex-start
                                    align-items: flex-start
                                    height: 100%
                                    margin: 5px 0px 10px 0px
                                    @include text
                                    &__name
                                        color: $secondary-color
                                        font-weight: 600
                                        text-decoration: none
                                        // margin-bottom: 5px
                                        @include transition
                                        &:hover
                                            text-decoration: underline
                                            color: #c48e19
                                            @include transition
                                        // &:visited
                                        //     color: #460e52
                                    &__date
                                        margin-bottom: 10px
                                        font-size: 13px
                                        color: #616161b6
                                        font-weight: 400
                                        text-decoration: none
                                        &:hover
                                            text-decoration: underline
                                            color: #c48e19
                                            @include transition
                                    &__text
                                        @include text
                                        color: $secondary-color
                                        font-weight: 400
                                        font-size: 16px
                                        line-height: 175%
                                        max-height: 10em
                                        overflow-y: auto
                                        padding-right: 10px


