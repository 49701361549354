@import 'variables'

.main
    min-width: 100%
    max-width: 100%
    &__up
        position: fixed
        right: 20px
        bottom: 100px
        width: 65px
        z-index: 100
        cursor: pointer
    &__choice
        &__modal
            &__layout
                border-radius: 30px
                max-width: 90vw !important
                display: flex
                justify-content: center
                align-items: center
                &__content
                    position: relative
                    width: fit-content
                    max-width: 70vw
                    display: flex
                    flex-direction: column
                    padding: 15px
                    @media (max-width: 768px)
                        max-width: 90vw
                    &__title
                        @include header3
                        text-align: center
                        margin-bottom: 20px
                        color: $secondary-color
                        @media (max-width: 670px)
                            font-size: 20px
                        @media (max-width: 550px)
                            font-size: 18px
                            padding: 0px 15px
                    &__a
                        @include link
                        color: $secondary-color
                        text-decoration: none
                        text-align: center
                        margin-bottom: 10px
                        @include transition
                        &:hover
                            text-decoration: underline
                            color: #c18f0d
                            @include transition
                    &__p
                        @include link
                        color: $secondary-color
                        text-decoration: none
                        text-align: start
                        padding: 0px 50px
                        margin-bottom: 10px
                        line-height: 200%
                        @include transition
                    &__sales
                        padding: 15px
                        @media (max-width: 768px)
                            padding: 5px
                        &__note
                            font-family: 'main'
                            margin-bottom: 10px
                            font-size: 14px
                            color: #414141b6
                            color: #ff3c00d8
                            font-weight: 400
                            text-decoration: none
                            text-align: end
                        &__sale
                            margin-bottom: 20px
                            &__title
                                @include header3
                                color: $secondary-color
                                font-size: 20px
                                margin-bottom: 10px
                                @media (max-width: 768px)
                                    font-size: 16px
                            &__text
                                @include text
                                font-size: 16px
                                line-height: 175%
                                color: $secondary-color
                                margin-left: 10px
                                @media (max-width: 768px)
                                    font-size: 14px
    &__section
        // display: flex
        // flex-direction: column
        // justify-content: center
        // align-items: center
        // background: url('../img/background-general.svg') no-repeat
        background-attachment: fixed
        background-size: cover
        min-height: fit-content
        max-height: fit-content
        padding-bottom: 0px
        position: relative
        overflow: hidden
        // &:nth-child(2)
        //     padding-bottom: 50px
        &__gradient
            position: absolute
            top: 0px
            object-fit: cover
            width: 100%
        &__greeting
            width: 100vw
            position: relative
            @include block
            flex-direction: column
            background-color: $secondary-color
            overflow: hidden
            min-height: fit-content
            max-height: fit-content 
            &__china1
                position: absolute
                top: 0px
                width: 10vw
                @media (max-width: 1024px)
                    width: 20vw
            &__china2
                position: absolute
                top: 0px
                width: 20vw
                margin-left: 400px
                @media (max-width: 1024px)
                    width: 40vw
            &__waves
                position: absolute
                object-fit: cover
                bottom: 0px
                width: 100%
            &__content
                @include content       
                width: 90vw
                justify-content: flex-start
                min-height: 55rem
                @media (max-width: 425px)
                    max-height: fit-content
                    min-height: 45rem
                &__number
                    position: absolute
                    top: 20px
                    right: 0px
                    @include link
                    color: $main-color
                    margin-bottom: 20px
                    font-size: 24px
                    @include transition
                    &:hover
                        @include transition
                        color: $third-color
                    @media (max-width: 1000px)
                        left: 0px
                &__left
                    display: flex
                    flex-direction: column
                    align-items: flex-start
                    justify-content: center
                    min-width: 50vw
                    z-index: 2
                    &__h1
                        @include header1
                        color: $main-color
                        width: 50vw
                        align-self: flex-start
                        margin-bottom: 20px
                        @media (max-width: 1024px)
                            font-size: 48px
                        @media (max-width: 768px)
                            font-size: 36px
                        @media (max-width: 425px)
                            font-size: 30px
                    &__p
                        @include text
                        color: $main-color
                        align-self: flex-start
                        margin-bottom: 20px
                        font-size: 24px
                        @media (max-width: 768px)
                            font-size: 20px
                        @media (max-width: 650px)
                            font-size: 18px
                        @media (max-width: 400px)
                            font-size: 16px
                    &__button
                        align-self: flex-end
                        @include buttonContainer
                        max-width: 250px
                        &__hover
                            @include button
                    &__choiceButton
                        // position: absolute
                        // top: 20px
                        // left: 50px
                        margin-bottom: 100px
                        align-self: flex-start
                        max-width: 250px
                        // padding: 15px 5px
                        @include buttonContainer
                        // border-radius: 30px
                        // box-shadow: inset 9px 9px 10px 0px rgba(0, 0, 0, 0.61)
                        &__hover
                            cursor: pointer
                            @include transition
                            border: none
                            @include button
                            background-color: #ffffff00
                            text-transform: none
                            @include link
                            text-transform: uppercase
                            color: $third-color
                            &:hover
                                // text-decoration: underline
                                @include transition
                                
                &__right
                    position: relative
                    width: 100%
                    height: 100%
                    &__china1
                        position: absolute
                        top: 0px
                        width: 100px






.swiper-slide-shadow
    display: none !important

.swiper-button-prev
    content: url(../img/arrow_left.svg)
    left: 0px !important

.swiper-button-next
    content: url(../img/arrow_right.svg)
    right: 0px !important


.swiper-pagination-bullet
    background-color: rgba(35, 32, 32, 0.30) !important
    box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.25) inset
.swiper-pagination-bullet-active
    background-color: #FFCB03 !important
    box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.25) inset

.hystmodal__close
    &:focus
        outline: none !important


.hystmodal__window
    background-color: #ffffff00 !important




.sliderComments
    max-width: 70vw
    min-width: 70vw



